<template slot="agentFunctions">
  <div>
    <vs-row class="mt-4">
      <vs-col vs-w="2">
        <div class="flex flex-col items-start mr-2">
          <label class="mb-2">{{ $t("BackToFlow") }}</label>
          <vs-switch
            v-model="backToFlow"
            vs-icon-off="close"
            vs-icon-on="done"
          />
          <template v-if="backToFlow">
            <label class="vs-input--label mt-2">{{
              $t("ForceQuestion")
            }}</label>
            <vs-select class="w-auto" type="text" v-model="toIndexValue">
              <vs-select-item
                v-for="(label, index) in labelsWithIndexes"
                :key="index"
                :value="label.value"
                :text="label.label"
              />
            </vs-select>
          </template>
        </div>
      </vs-col>
      <vs-col vs-w="10">
        <textarea
          rows="10"
          class="border border-gray-300 rounded p-2 w-full"
          v-model="backToFlowReason"
        ></textarea>
      </vs-col>
    </vs-row>

    <vs-row class="mt-4">
      <vs-col vs-w="2">
        <div class="flex flex-col items-start">
          <label class="mb-2">{{ $t("HumanTransfer") }}</label>
          <vs-switch
            v-model="humanTransfer"
            vs-icon-off="close"
            vs-icon-on="done"
          />
        </div>
      </vs-col>
      <vs-col vs-w="10">
        <textarea
          rows="10"
          class="border border-gray-300 rounded p-2 w-full"
          v-model="humanTransferReason"
        ></textarea>
      </vs-col>
    </vs-row>

    <vs-row class="mt-4">
      <vs-col vs-w="2">
        <div class="flex flex-col items-start">
          <label class="mb-2">{{ $t("CloseConversation") }}</label>
          <vs-switch
            v-model="closeConversation"
            vs-icon-off="close"
            vs-icon-on="done"
          />
        </div>
      </vs-col>
      <vs-col vs-w="10">
        <textarea
          rows="10"
          class="border border-gray-300 rounded p-2 w-full"
          v-model="closeConversationReason"
        ></textarea>
      </vs-col>
    </vs-row>

    <vs-row class="mt-4">
      <vs-col vs-w="2">
        <div class="flex flex-col items-start">
          <label class="mb-2">{{ $t("AddTag") }}</label>
          <vs-switch v-model="addTag" vs-icon-off="close" vs-icon-on="done" />
        </div>
      </vs-col>
      <vs-col vs-w="10">
        <textarea
          rows="10"
          class="border border-gray-300 rounded p-2 w-full"
          v-model="addTagReason"
        ></textarea>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  name: "AgentFunctions",
  props: {
    agent: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      required: true,
      type: Boolean,
      default: false,
    },
    toIndex: String,
  },
  data() {
    return {
      toIndexValue: null,
      backToFlow: false,
      addTag: false,
      humanTransfer: false,
      closeConversation: false,
      backToFlowReason: "",
      humanTransferReason: "",
      closeConversationReason: "",
      addTagReason: "",
      aiFunctionBackToFlowDefault: `{
  "description": "Quando terminar o que esta estipulado no seu prompt ou nao souber responder execute essa função",
  "parameters": {
    "type": "object",
    "properties": {},
    "required": []
  }
}`,
      aiFunctionAddTagDefault: `{
  "description": "Determine alguma tag para o usuário quando pegar alguma informação importante como empresa, cargo, email, telefone",
  "parameters": {
    "type": "object",
    "properties": {
      "tag": {
        "type": "string",
        "description": "exemplo de tag 'empresa: valor', 'email: valor' sempre com chave: valor"
      }
    },
    "required": [
      "tag"
    ]
  }
}`,
      aiFunctionCloseConversationDefault: `{
  "description": "Execute essa função quando o usuário nao quiser mais conversar",
  "parameters": {
    "type": "object",
    "properties": {},
    "required": []
  }
}`,
      aiFunctionForwardHumanDefault: `{
  "description": "Se a pessoa quiser mais informações ou informações que voce nao possua determine o setor para a pessoa: -vendas -suporte",
  "parameters": {
      "type": "object",
      "properties": {
          "setor": {
              "type": "string",
              "description": "vendas ou suporte"
          }
      },
      "required": ["setor"]
  }
}`,
    };
  },
  mounted() {
    this.toIndexValue = this.toIndex;

    this.backToFlow = !!this.getFunction("back_to_flow").enabled;
    this.addTag = !!this.getFunction("add_tag").enabled;
    this.humanTransfer = !!this.getFunction("human_required").enabled;
    this.closeConversation = !!this.getFunction("close_conversation").enabled;
    this.backToFlowReason = this.getFunction("back_to_flow").function
      ? this.getFunction("back_to_flow").function
      : this.aiFunctionBackToFlowDefault;
    this.addTagReason = this.getFunction("add_tag").function
      ? this.getFunction("add_tag").function
      : this.aiFunctionAddTagDefault;
    this.humanTransferReason = this.getFunction("human_required").function
      ? this.getFunction("human_required").function
      : this.aiFunctionForwardHumanDefault;
    this.closeConversationReason = this.getFunction("close_conversation")
      .function
      ? this.getFunction("close_conversation").function
      : this.aiFunctionCloseConversationDefault;
  },
  methods: {
    getFunctions() {
      return [
        {
          name: "back_to_flow",
          enabled: this.backToFlow,
          type: "function",
          function: this.backToFlowReason,
        },
        {
          name: "add_tag",
          enabled: this.addTag,
          type: "function",
          function: this.addTagReason,
        },
        {
          name: "human_required",
          enabled: this.humanTransfer,
          type: "function",
          function: this.humanTransferReason,
        },
        {
          name: "close_conversation",
          enabled: this.closeConversation,
          type: "function",
          function: this.closeConversationReason,
        },
      ];
    },
    getToIndex() {
      return this.toIndexValue;
    },
    getFunction(name) {
      if (this.isEditing) {
        return (
          this.agent.functions.find((f) => f.name === name) || {
            name,
            enabled: false,
          }
        );
      }

      return {
        name,
        enabled: true,
      };
    },
    formFields2() {
      return this.$store.state.chatbotManagement.current_chatbot.formFields2;
    },
  },
  computed: {
    labelsWithIndexes: function () {
      return [
        {
          label: this.$t("None"),
          value: null,
        },
        ...this.formFields2().map((node, index) => ({
          label:
            node.label ||
            `${this.$t("MessageNoTitle")}. ${this.$t(
              "MessageNumber"
            )} ${index}`,
          value: node.id,
        })),
      ];
    },
  },
  watch: {},
};
</script>
<style lang="scss"></style>
