<template>
  <div>
    <!-- Textarea -->
    <vs-textarea
      ref="messageTextarea"
      label="Message Template"
      v-model="messageTemplate"
      :rows="5"
      placeholder="Type your message and insert variables like {name}, {email}, {age} ..."
      @input="extractUsedVariables"
    />

    <!-- Variable Selector -->
    <div class="mt-4">
      <div class="text-gray-700 font-medium mb-2">Insert an entity:</div>
      <div class="flex flex-wrap gap-2">
        <vs-button
          v-for="(path, alias) in localMessageVariables"
          :key="path"
          class="whitespace-nowrap"
          size="small"
          @click="insertVariable(alias)"
          :class="{ hidden: alias === 'mappedVariablesTransformation' }"
        >
          {{ alias }}
        </vs-button>
      </div>
    </div>

    <!-- Highlight Preview -->
    <vs-card class="mt-6" v-if="messageTemplate.length">
      <div slot="header">Preview:</div>
      <div
        class="p-3 rounded-md bg-gray-100 border border-gray-300 text-black whitespace-pre-wrap"
        v-html="highlightedMessage"
      />
    </vs-card>

    <!-- Used Variables, Custom Values, and Regex Transformations -->
    <div class="mt-6" v-if="messageTemplate.length">
      <div class="font-medium text-md mb-2">
        Define Values and Transformations for Used Variables:
      </div>
      <div class="flex flex-wrap" v-if="usedVariables.length > 0">
        <vs-card
          v-for="variable in usedVariables"
          :key="variable"
          class="w-auto p-4 mr-4 mb-4"
        >
          <div class="flex items-center justify-between mb-2">
            <span class="font-semibold text-sm">{{ variable }}</span>
            <span v-if="regexErrors[variable]" class="text-danger text-sm">
              Invalid Regex Pattern
            </span>
          </div>

          <!-- Custom Value Input -->
          <div class="mb-4">
            <vs-input
              v-model="variableValues[variable]"
              label="Test Value"
              placeholder="Enter a custom value for this variable..."
              @input="applyTransformation(variable)"
            />
          </div>

          <!-- Regex Transformations -->
          <div class="mb-4">
            <div
              v-for="(transformation, index) in variableRegexes[variable]"
              :key="index"
              class="mb-2 flex items-center space-x-2"
            >
              <vs-input
                v-model="transformation.pattern"
                label="Regex Pattern"
                placeholder="Enter regex pattern (e.g., Wed Feb)"
                @input="applyTransformation(variable)"
                :class="{ 'border-red-500': regexErrors[variable] }"
              />
              <vs-input
                v-model="transformation.replacement"
                label="Replacement String"
                placeholder="Enter replacement string (e.g., empty to remove)"
                @input="applyTransformation(variable)"
              />
              <vs-button
                class="mt-4"
                size="small"
                color="danger"
                icon="remove"
                @click="removeTransformation(variable, index)"
              />
            </div>
            <vs-button
              class="mt-2"
              color="success"
              size="small"
              @click="addTransformation(variable)"
            >
              + Add Transformation
            </vs-button>
          </div>

          <!-- Transformed Value Display -->
          <div>
            <strong>Transformed Value:</strong>
            <span
              :class="{
                'text-success': validationResults[variable],
                'text-danger': validationResults[variable] === false,
              }"
            >
              {{ transformedValues[variable] }}
            </span>
          </div>
        </vs-card>
      </div>
    </div>

    <!-- Save Button -->
    <div class="mt-6 flex justify-end">
      <vs-button
        size="small"
        color="primary"
        :disabled="!messageTemplate.length"
        @click="saveMessageTemplate"
      >
        {{ editIndex !== -1 ? "Update" : "Save" }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlowComposeMessage",

  props: {
    mappedVariables: {
      type: Object,
      default: () => ({}),
    },
    editIndex: {
      type: Number,
      default: -1,
    },
    editMessage: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      messageTemplate: "",
      localMessageVariables: {},
      usedVariables: [],
      variableValues: {},
      variableRegexes: {},
      regexErrors: {},
      transformedValues: {},
      validationResults: {},
    };
  },

  watch: {
    mappedVariables: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.localMessageVariables = { ...newVal };
        this.initializeVariableData();
      },
    },
    editMessage: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.messageTemplate = newVal.messageTemplate || "";
          this.usedVariables = { ...newVal.variables };
          this.variableRegexes = { ...newVal.regex };
          this.extractUsedVariables();
        }
      },
    },
  },

  computed: {
    highlightedMessage() {
      const variablePattern = /\{(\w+)\}/g;
      return this.messageTemplate.replace(variablePattern, (match, varName) => {
        if (this.variableValues[varName] !== undefined) {
          return `<span class="text-primary font-bold">${
            this.transformedValues[varName] || this.variableValues[varName]
          }</span>`;
        }
        return `<span class="text-red-500 underline">${match}</span>`;
      });
    },
  },

  methods: {
    addTransformation(variable) {
      if (!this.variableRegexes[variable]) {
        this.$set(this.variableRegexes, variable, []);
      }
      this.variableRegexes[variable].push({ pattern: "", replacement: "" });
    },

    removeTransformation(variable, index) {
      this.variableRegexes[variable].splice(index, 1);
      this.applyTransformation(variable);
    },

    initializeVariableData() {
      Object.keys(this.localMessageVariables).forEach((variable) => {
        if (!(variable in this.variableValues)) {
          this.$set(this.variableValues, variable, "");
        }
        if (!(variable in this.variableRegexes)) {
          this.$set(this.variableRegexes, variable, {
            pattern: "",
            replacement: "",
          });
        }
        if (!(variable in this.regexErrors)) {
          this.$set(this.regexErrors, variable, false);
        }
        if (!(variable in this.transformedValues)) {
          this.$set(
            this.transformedValues,
            variable,
            this.variableValues[variable]
          );
        }
        if (!(variable in this.validationResults)) {
          this.$set(this.validationResults, variable, null);
        }
      });
    },

    insertVariable(varName) {
      const textAreaEl =
        this.$refs.messageTextarea &&
        this.$refs.messageTextarea.$el &&
        this.$refs.messageTextarea.$el.querySelector("textarea");
      const insertionText = `{${varName}}`;

      if (textAreaEl && typeof textAreaEl.selectionStart !== "undefined") {
        const start = textAreaEl.selectionStart;
        const end = textAreaEl.selectionEnd;

        const before = this.messageTemplate.slice(0, start);
        const after = this.messageTemplate.slice(end);

        this.messageTemplate = before + insertionText + after;

        this.$nextTick(() => {
          const newPos = start + insertionText.length;
          textAreaEl.selectionStart = newPos;
          textAreaEl.selectionEnd = newPos;
          textAreaEl.focus();
        });
      } else {
        this.messageTemplate += insertionText;
      }

      this.extractUsedVariables();
    },

    /**
     * Applies the regex transformation to a variable's value.
     * Updates transformedValues and tracks regex errors.
     * @param {String} variable - The variable name.
     */
    applyTransformation(variable) {
      // Ensure transformations is always an array
      if (!Array.isArray(this.variableRegexes[variable])) {
        this.$set(this.variableRegexes, variable, []);
      }

      let value = this.variableValues[variable];

      try {
        this.variableRegexes[variable].forEach(({ pattern, replacement }) => {
          if (pattern) {
            const regex = new RegExp(pattern, "g");
            value = value.replace(regex, replacement);
          }
        });

        this.$set(this.transformedValues, variable, value);
        this.$set(this.regexErrors, variable, false);
        this.$set(this.validationResults, variable, true);
      } catch (e) {
        this.$set(this.regexErrors, variable, true);
        this.$set(
          this.transformedValues,
          variable,
          this.variableValues[variable]
        );
        this.$set(this.validationResults, variable, false);
      }
    },

    extractUsedVariables() {
      const variablePattern = /\{(\w+)\}/g;
      const matches = [];
      let match;
      while ((match = variablePattern.exec(this.messageTemplate)) !== null) {
        matches.push(match[1]);
      }
      this.usedVariables = Array.from(new Set(matches));

      this.usedVariables.forEach((variable) => {
        if (!(variable in this.variableValues)) {
          this.$set(this.variableValues, variable, `{${variable}}` || "");
        }
        if (!(variable in this.variableRegexes)) {
          this.$set(this.variableRegexes, variable, {
            pattern: "",
            replacement: "",
          });
        }

        // Apply transformation to initialize transformedValues
        this.applyTransformation(variable);
      });

      Object.keys(this.variableValues).forEach((variable) => {
        if (!this.usedVariables.includes(variable)) {
          this.$delete(this.variableValues, variable);
          this.$delete(this.variableRegexes, variable);
        }
      });
    },

    saveMessageTemplate() {
      this.$emit(
        "save",
        "sendMessage",
        {
          messageTemplate: this.messageTemplate,
          variables: this.usedVariables,
          regex: Object.fromEntries(
            Object.entries(this.variableRegexes).filter(([key]) =>
              this.usedVariables.includes(key)
            )
          ),
        },
        this.editIndex
      );
      this.messageTemplate = "";
    },
  },
};
</script>
