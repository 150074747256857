<template>
  <vx-card no-shadow class="w-full">
    <div class="vx-row">
      <div class="w-full">
        <div slot="no-body" class="w-full">
          <chatbot-flow v-if="loadComponents && pageFlow" class="w-full" />
          <chatbot-management
            v-else-if="loadComponents && pageChatbot"
            class="p-8"
            :templateId="$route.params.template_id"
          />
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moduleChatbotManagement from "@/store/chatbot-management/moduleChatbotManagement.js";

import ChatbotManagement from "./ChatbotManagement.vue";
import ChatbotFlow from "./flow/ChatbotFlow.vue";

export default {
  components: {
    ChatbotManagement,
    ChatbotFlow,
  },
  data() {
    return {
      publish: false,
      activeTab: 0,
      chatSeted: false,
      loadComponents: false,
    };
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    currentRouteName() {
      return this.$route.name;
    },
    pageFlow() {
      return (
        this.currentRouteName === "chatbot-flow-edit" ||
        this.currentRouteName === "chatbot-flow-hybrid-edit"
      );
    },
    pageChatbot() {
      return (
        this.currentRouteName === "chatbot-edit" ||
        this.currentRouteName === "chatbot-hybrid-edit" ||
        this.currentRouteName === "chatbot-create"
      );
    },
  },
  created() {
    if (!moduleChatbotManagement.isRegistered) {
      this.$store.registerModule("chatbotManagement", moduleChatbotManagement);
      moduleChatbotManagement.isRegistered = true;
    }
  },
  mounted() {
    if (this.$route.params.chatbot_id != null) {
      this.$store
        .dispatch(
          "chatbotManagement/fetchChatbot",
          this.$route.params.chatbot_id
        )
        .then(() => {
          this.loadComponents = true;
        });
    } else {
      this.loadComponents = true;
    }
  },
};
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--ul {
    flex-wrap: nowrap;
  }
}
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
