<template>
  <div class="custom-path-builder">
    <span class="font-bold mb-2">Build Your JSONPath</span>

    <!-- Current path display -->
    <div class="bg-primary-light p-2 rounded-lg mb-2">
      <strong>Current Path:</strong>
      <span class="ml-2">{{ currentJsonPath }}</span>
    </div>

    <!-- If the current node is an object or array, show choices -->
    <div v-if="typeof currentNode === 'object' && currentNode !== null">
      <!-- If it's an array, let user pick [*] or a numeric index -->
      <div v-if="Array.isArray(currentNode)">
        <p class="mb-1">
          This is an array with {{ currentNode.length }} items.
        </p>
        <vs-button class="mr-2" size="small" @click="chooseWildcard"
          >[*] (All Items)</vs-button
        >
        <vs-button
          v-for="(item, idx) in currentNode"
          :key="idx"
          size="small"
          class="mr-2 mb-2"
          @click="chooseIndex(idx)"
        >
          [{{ idx }}]
        </vs-button>
      </div>

      <!-- If wildcard is selected, show all nested object elements -->
      <div v-if="pathSteps.includes('*') && typeof currentNode[0] === 'object'">
        <p class="mb-1">Select a field within wildcard:</p>
        <vs-button
          v-for="key in getNestedKeys(currentNode)"
          :key="key"
          size="small"
          class="mr-2 mb-2"
          @click="chooseKey(key)"
        >
          {{ key }}
        </vs-button>
      </div>

      <!-- If it's an object, list its keys -->
      <div v-else>
        <p class="mb-1">
          This is an object with {{ Object.keys(currentNode).length }} keys.
        </p>
        <vs-button
          v-for="key in sortedKeys"
          :key="key"
          size="small"
          class="mr-2 mb-2"
          @click="chooseKey(key)"
        >
          {{ key }}
        </vs-button>
      </div>
    </div>

    <!-- Conditional Filters UI -->
    <div class="mt-3 flex items-center space-x-2">
      <vs-select v-model="conditionField" placeholder="Select Field">
        <vs-select-item
          v-for="key in sortedKeys"
          :key="key"
          :value="key"
          :text="key"
        />
      </vs-select>
      <vs-select v-model="conditionOperator" placeholder="Operator">
        <vs-select-item value=">" text=">" />
        <vs-select-item value="<" text="<" />
        <vs-select-item value=">=" text=">=" />
        <vs-select-item value="<=" text="<=" />
        <vs-select-item value="==" text="==" />
        <vs-select-item value="!=" text="!=" />
      </vs-select>
      <vs-input v-model="conditionValue" type="text" placeholder="Value" />
      <vs-button
        size="small"
        type="filled"
        color="primary"
        @click="addCondition"
        >Add Condition</vs-button
      >
    </div>

    <!-- Navigation: step back or done -->
    <div class="mt-3 space-x-2">
      <vs-button
        v-if="pathSteps.length > 0"
        size="small"
        type="border"
        color="warning"
        @click="removeLastStep"
        >Go Up</vs-button
      >
      <vs-button size="small" type="filled" color="success" @click="finishPath"
        >Done</vs-button
      >
      <vs-button
        size="small"
        type="border"
        color="danger"
        @click="cancelPath"
        class="right"
        >Cancel</vs-button
      >
    </div>
  </div>
</template>

<script>
function getValueAtPath(jsonData, pathSteps) {
  let current = jsonData;
  for (const step of pathSteps) {
    if (current === undefined || current === null) return undefined;
    current = current[step === "*" ? 0 : step];
  }
  return current;
}

function buildJsonPath(pathSteps, condition) {
  let path = "$";
  for (const step of pathSteps) {
    if (typeof step === "number") {
      path += `[${step}]`;
    } else if (step === "*") {
      path += "[*]";
    } else if (/^[A-Za-z_$][A-Za-z0-9_$]*$/.test(step)) {
      path += `.${step}`;
    } else {
      path += `['${step}']`;
    }
  }

  // Ensure condition is correctly applied at the array level
  if (condition) {
    path = path.replace("[*]", ""); // Remove wildcard before applying condition
    path += `[?(@.${condition.field} ${condition.operator} ${JSON.stringify(
      condition.value
    )})]`;
  }
  return path;
}

export default {
  name: "CustomPathBuilder",
  props: {
    jsonData: {
      type: [Object, Array],
      required: true,
    },
    initialPath: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pathSteps: [...this.initialPath],
      conditionField: "",
      conditionOperator: "==",
      conditionValue: "",
      condition: null,
    };
  },
  computed: {
    sortedKeys() {
      if (!this.currentNode || typeof this.currentNode !== "object") return [];
      return Object.keys(this.currentNode).sort();
    },
    currentNode() {
      return getValueAtPath(this.jsonData, this.pathSteps);
    },
    currentJsonPath() {
      return buildJsonPath(this.pathSteps, this.condition);
    },
  },
  methods: {
    chooseKey(key) {
      this.pathSteps.push(key);
    },
    chooseIndex(idx) {
      this.pathSteps.push(idx);
    },
    chooseWildcard() {
      this.pathSteps.push("*");
    },
    getNestedKeys(array) {
      if (array.length > 0 && typeof array[0] === "object") {
        return Object.keys(array[0]);
      }
      return [];
    },
    removeLastStep() {
      this.pathSteps.pop();
    },
    addCondition() {
      if (
        this.conditionField &&
        this.conditionOperator &&
        this.conditionValue
      ) {
        this.condition = {
          field: this.conditionField,
          operator: this.conditionOperator,
          value: isNaN(this.conditionValue)
            ? this.conditionValue
            : Number(this.conditionValue),
        };
      }
    },
    finishPath() {
      const finalPath = buildJsonPath(this.pathSteps, this.condition);
      this.$emit("done", finalPath);
    },
    cancelPath() {
      this.$emit("done", null);
    },
  },
};
</script>

<style scoped>
.custom-path-builder {
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 8px;
}
</style>
