<template>
  <div class="w-full">
    <!-- PERGUNTA -->
    <draggable
      v-model="flow"
      v-if="flow.length > 0"
      @change="emitSaveFlow(flow)"
    >
      <div
        v-for="(question, questionIndex) in flow"
        :key="questionIndex"
        class="mt-6"
      >
        <!-- EDITAR PERGUNTA -->
        <div v-if="edit === questionIndex && editChildren === 'none'">
          <chatbot-question-form
            ref="questionForm"
            :questionForm="question"
            :questionIndex="questionIndex"
            :indexArrayPosition="indexArrayPosition"
            :isAgent="isAgent"
            @cancel-agent-edition="cancelAgentEdition"
            @cancel-edit="cancelQuestionEdition"
            @save-question="saveQuestion"
            @open-webhook-popup="openWebhookPopup"
            icon="MoveIcon"
          >
          </chatbot-question-form>
        </div>
        <div
          v-if="
            edit != questionIndex ||
            (edit === questionIndex && editChildren != 'none')
          "
        >
          <!-- DIV DA PERGUNTA -->

          <div
            :class="{
              'text-primary': !question.data.agentId,
              'assistant-color': question.data.agentId,
            }"
            class="label-onboarding text-base"
          >
            {{ getIndexTitle }}{{ questionIndex + 1 }}
          </div>
          <div
            v-if="question.data"
            :class="{
              'border-primary': !question.data.agentId,
              'assistant-color': question.data.agentId,
            }"
            class="rounded-lg border border-solid mb-6 flex shadow-md"
          >
            <div
              class="cursor-move flex item-center self-stretch border-solid border-primary border-0 border-r p-4"
            >
              <feather-icon
                v-if="!question.data.agentId"
                icon="MoveIcon"
                svgClasses="w-4 h-4 text-primary"
              />
              <feather-icon
                v-else
                icon="MoveIcon"
                svgClasses="w-4 h-4 assistant-color"
              />
            </div>
            <div class="flex flex-wrap w-full">
              <div class="flex justify-between items-start w-full py-4">
                <h4 class="pl-4 pb-0 w-full">
                  {{
                    Array.isArray(question.data.text)
                      ? question.data.text[0]
                      : question.data.text
                  }}
                  <div class="flex flex-wrap w-full">
                    <div class="w-full">
                      <vs-chip
                        transparent
                        class="mt-6"
                        :color="
                          question.input_type === 'assistant'
                            ? 'warning'
                            : 'default'
                        "
                      >
                        <div
                          v-if="question.input_type === 'assistant'"
                          class="bg-warning con-vs-avatar"
                        >
                          <iconify
                            icon="fluent-mdl2:robot"
                            class="text-white text-2xl"
                          />
                        </div>
                        <vs-avatar
                          v-else
                          icon="icon-info"
                          icon-pack="feather"
                          id="iconInfoAvatar"
                        />
                        {{ getResponseType(question) }}
                      </vs-chip>
                      <div
                        v-for="(header, headerIndex) in getMessageHeaders(
                          question,
                          false
                        )"
                        :key="headerIndex"
                      >
                        <vs-chip
                          v-for="(headerItem, headerItemIndex) in header"
                          :key="headerItemIndex"
                          :color="headerIndex !== 2 ? 'primary' : 'warning'"
                          class="mt-6"
                          transparent
                        >
                          <vs-avatar
                            :icon="
                              headerIndex === 0
                                ? 'icon-at-sign'
                                : headerIndex === 2
                                ? 'icon-fast-forward'
                                : 'icon-hash'
                            "
                            icon-pack="feather"
                            :style="
                              headerIndex !== 2
                                ? 'background-color: #aba3f6'
                                : 'background-color: rgb(255,159,67)'
                            "
                          />
                          {{
                            headerItem[0] === "@" || headerItem[0] === "#"
                              ? headerItem.slice(1, headerItem.length)
                              : headerItem
                          }}
                        </vs-chip>
                      </div>
                    </div>
                  </div>
                </h4>
                <vs-dropdown
                  class="pl-4 pr-2 cursor-pointer"
                  vs-custom-content
                  vs-trigger-click
                >
                  <a class="a-icon" href.prevent>
                    <feather-icon
                      v-if="question.data.agentId"
                      icon="MoreVerticalIcon"
                      svgClasses="w-6 h-6 assistant-color"
                    />
                    <feather-icon
                      v-else
                      icon="MoreVerticalIcon"
                      svgClasses="w-6 h-6 text-primary"
                    />
                  </a>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <div
                        @click="editQuestion(questionIndex)"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="EditIcon"
                          class="inline-block mr-2 text-success"
                          svgClasses="w-6 h-6"
                        />
                        <span class="text-lg">{{ $t("Edit") }}</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div
                        @click="duplicateQuestion(questionIndex)"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="CopyIcon"
                          class="inline-block mr-2"
                          svgClasses="w-6 h-6 text-success"
                        />
                        <span class="text-lg">Duplicar</span>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div
                        @click="confirmRemoveQuestion(questionIndex)"
                        class="flex items-center"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="inline-block mr-2"
                          svgClasses="w-6 h-6 text-danger"
                        />
                        <span class="text-lg">Excluir</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </div>
          <!-- se houver sub fluxo -->
          <draggable v-model="question.children" @change="emitSaveFlow(flow)">
            <div
              v-for="(children, indexChildren) in question.children"
              :key="indexChildren"
            >
              <!-- EDITAR SUBFLUXO IDENTADO -->
              <div class="label-onboarding text-primary text-base ml-12">
                {{ getIndexTitle }}{{ questionIndex + 1 }}.{{
                  indexChildren + 1
                }}
              </div>
              <div
                v-if="editChildren === indexChildren && edit === questionIndex"
                class="rounded-lg border border-solid border-primary ml-12 mb-6 pb-0 p-6 flex flex-wrap shadow-md"
              >
                <!-- NOME DO SUB FLUXO -->
                <div class="flex relative flex-wrap mb-3 w-full">
                  <input
                    maxlength="20"
                    type="text"
                    class="h-12 w-full vs-inputx vs-input--input input-onboarding"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    danger-text="1|2|3"
                    name="intention"
                    v-model="children.id"
                    @keyup="keyupInputSubflowName"
                    @blur="blurInputSubflowName"
                  />

                  <span class="font-semibold absolute floatCounter">{{
                    children.id.length
                  }}</span>

                  <span class="text-danger text-sm">{{
                    errors.first("intention")
                  }}</span>
                  <div class="label-onboarding">
                    Nome do sub fluxo ({{ children.id.length }}/20)
                  </div>
                </div>
                <!-- DESCRICAO DO SUB FLUXO -->
                <div class="flex relative flex-wrap mb-3 w-full">
                  <input
                    maxlength="40"
                    type="text"
                    class="h-12 w-full vs-inputx vs-input--input input-onboarding"
                    data-vv-validate-on="blur"
                    danger-text="1|2|3"
                    name="description"
                    v-model="children.description"
                    @keyup="keyupInputSubflowDescription(question, children)"
                  />

                  <span class="font-semibold absolute floatCounter">{{
                    children.description ? children.description.length : 0
                  }}</span>

                  <span class="text-danger text-sm">{{
                    errors.first("description")
                  }}</span>
                  <div class="label-onboarding">
                    Descricao do fluxo ({{
                      children.description ? children.description.length : 0
                    }}/40)
                  </div>
                </div>
                <chatbot-questions
                  :formFields="children.data"
                  :subflow="true"
                  :indexQuestion="questionIndex"
                  :indexChildren="indexChildren"
                  :indexArrayPosition="indexArrayPosition + 1"
                  :indexTitle="`${getIndexTitle}${questionIndex + 1}.${
                    indexChildren + 1
                  }.`"
                  :isCustom="isCustom"
                  @save-flow="saveSubflow"
                  @cancel-subquestion-edition="cancelSubQuestionEdition"
                >
                </chatbot-questions>
              </div>

              <!-- DIV SUBFLUXO IDENTADO -->
              <div
                v-else
                class="rounded-lg border border-solid border-primary mb-3 ml-12 flex shadow-md"
              >
                <div
                  class="cursor-move flex item-center self-stretch border-solid border-primary border-0 border-r p-4"
                >
                  <feather-icon
                    icon="MoveIcon"
                    svgClasses="w-4 h-4 text-primary"
                  />
                </div>
                <div class="flex flex-wrap w-full p-4">
                  <div class="flex justify-between items-center w-full">
                    <h4 class="pr-4">{{ children.id }}</h4>
                    <vs-dropdown
                      class="cursor-pointer"
                      vs-custom-content
                      vs-trigger-click
                    >
                      <a class="a-icon" href.prevent>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          svgClasses="w-6 h-6 text-primary"
                        />
                      </a>
                      <vs-dropdown-menu>
                        <vs-dropdown-item>
                          <div
                            @click="editQuestion(questionIndex, indexChildren)"
                            class="flex items-center"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="inline-block mr-2 text-success"
                              svgClasses="w-6 h-6"
                            />
                            <span class="text-lg">{{ $t("Edit") }}</span>
                          </div>
                        </vs-dropdown-item>
                        <vs-dropdown-item>
                          <div
                            @click="
                              duplicateQuestion(questionIndex, indexChildren)
                            "
                            class="flex items-center hidden"
                          >
                            <feather-icon
                              icon="CopyIcon"
                              class="inline-block mr-2"
                              svgClasses="w-6 h-6 text-success"
                            />
                            <span class="text-lg">Duplicar</span>
                          </div>
                        </vs-dropdown-item>
                        <vs-dropdown-item>
                          <div
                            @click="
                              confirmRemoveQuestion(
                                questionIndex,
                                indexChildren
                              )
                            "
                            class="flex items-center"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              class="inline-block mr-2"
                              svgClasses="w-6 h-6 text-danger"
                            />
                            <span class="text-lg">Excluir</span>
                          </div>
                        </vs-dropdown-item>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                  <div class="flex flex-wrap justify-end pr-4 w-full">
                    <div
                      v-for="(data, dataIndex) in children.data"
                      :key="dataIndex"
                      class="flex"
                    >
                      <div
                        v-for="(header, headerIndex) in getMessageHeaders(
                          data,
                          true
                        )"
                        :key="headerIndex"
                      >
                        <vs-chip
                          v-for="(headerItem, headerItemIndex) in header"
                          :key="headerItemIndex"
                          :color="headerIndex !== 2 ? 'primary' : 'warning'"
                          class="mt-6"
                          transparent
                        >
                          <vs-avatar
                            :icon="
                              headerIndex === 0
                                ? 'icon-at-sign'
                                : headerIndex === 2
                                ? 'icon-fast-forward'
                                : 'icon-hash'
                            "
                            icon-pack="feather"
                            :style="
                              headerIndex !== 2
                                ? 'background-color: #aba3f6'
                                : 'background-color: rgb(255,159,67)'
                            "
                          />
                          {{
                            headerItem[0] === "@" || headerItem[0] === "#"
                              ? headerItem.slice(1, headerItem.length)
                              : headerItem
                          }}
                        </vs-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </draggable>

    <!-- ADICIONAR PERGUNTA -->
    <div id="addQuestion">
      <!-- FORM ADICIONAR PERGUNTA -->
      <div v-if="edit == 'new'">
        <chatbot-question-form
          :questionForm="newQuestion"
          :isAgent="isAgent"
          :indexArrayPosition="indexArrayPosition"
          @cancel-agent-edition="cancelAgentEdition"
          @cancel-edit="cancelQuestionEdition"
          @save-question="saveQuestion"
          icon="SlashIcon"
        >
        </chatbot-question-form>
      </div>
      <!-- BOTÃO SHOW ADICIONAR PERGUNTA -->

      <div class="flex gap-x-4">
        <vs-button
          v-if="edit !== 'new'"
          class="w-full mt-8"
          icon-pack="feather"
          icon="icon-plus"
          @click="
            isAgent = false;
            edit = 'new';
          "
        >
          Adicionar Pergunta
        </vs-button>
        <vs-button
          v-if="this.$route.fullPath.includes('hybrid') && edit !== 'new'"
          class="w-full mt-8"
          icon-pack="feather"
          icon="icon-plus"
          @click="
            isAgent = true;
            edit = 'new';
          "
        >
          Adicionar Agente IA
        </vs-button>
      </div>
      <!-- BOTÃO SALVAR FLUXO -->
      <vs-button
        v-if="!subflow"
        class="w-full text-white mt-6"
        color="success"
        @click="saveFlow()"
      >
        {{ $t("SaveFlow") }}
      </vs-button>
      <vs-button
        v-if="!subflow"
        class="w-full text-white mt-6 text-primary"
        type="border"
        @click="saveFlow(), $router.push({ name: 'chatbots' })"
      >
        {{ $t("SaveFlowAndReturn") }}
      </vs-button>
      <div v-else class="flex flex-wrap justify-between w-full">
        <vs-button
          class="w-full mt-8 mb-6"
          color="danger"
          type="border"
          @click="$emit('cancel-subquestion-edition')"
        >
          Fechar
        </vs-button>
      </div>
    </div>

    <vs-popup
      fullscreen
      :title="$t('ChatbotPreview')"
      :active.sync="webhookPopup"
    >
      <div class="vx-col w-full">
        <flow-webhook
          v-if="webhookVersion === 1"
          :questionId="webhookQuestionId"
          :entities="entities"
          :intentions="intentions"
          :key="webhookComponentKey + '-v1'"
          @whversionone="webhookVersion = 1"
          @whversiontwo="webhookVersion = 2"
        />
        <flow-webhook-v2
          v-else
          :webhookWebhooksIds="webhookWebhooksIds"
          :entities="entities"
          :intentions="intentions"
          :key="webhookComponentKey"
          @save="handlerSaveWebhookToQuestion"
        />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ChatbotQuestionForm from "./ChatbotQuestionForm.vue";
import responseOptions from "./responseOptions";
import FlowWebhook from "./FlowWebhook.vue";
import FlowWebhookV2 from "./FlowWebhookV2.vue";
import { deepChangeId } from "@/helpers/flowEdit.js";

export default {
  name: "chatbot-questions",
  components: {
    draggable,
    ChatbotQuestionForm,
    FlowWebhook,
    FlowWebhookV2,
  },
  watch: {
    formFields: function (formFields) {
      const flow = JSON.parse(JSON.stringify(formFields));
      let treatedFlow = [];
      flow.forEach((item) => {
        if (Object.keys(item).length != 0) treatedFlow.push(item);
      });
      this.flow = treatedFlow;
      if (!this.isCustom) {
        this.edit = "none";
        this.editChildren = "none";
      }
    },
  },
  computed: {
    getIndexTitle: function () {
      if (this.indexTitle && this.indexTitle.length)
        return `${this.indexTitle}${
          this.indexTitle[this.indexTitle.length - 1] === "." ? "" : "."
        }`;
      return "";
    },

    AgentBorderColor: function () {
      return this.question.data.input_type === "assistant";
    },
  },
  props: {
    formFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    subflow: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    indexQuestion: {
      required: false,
    },
    indexChildren: {
      required: false,
    },
    indexArrayPosition: {
      type: Number,
      required: true,
    },
    indexTitle: {
      type: String,
      required: false,
    },
    isCustom: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      webhookVersion: 1,
      webhookComponentKey: 1,
      webhookWebhooksIds: [],
      webhookQuestionId: null,
      webhookPopup: false,
      isAgent: false,
      edit: "none",
      editChildren: "none",
      entities: [],
      flow: [],
      flowAlreadyLoaded: false,
      intentions: [],
      shortcut: [],
      newQuestion: {
        id: String(`${Math.random() * 500 + Date.now()}`),
        author: "",
        data: {
          text: "",
          file: "",
        },
        label: "",
        entity: "",
        intent: "",
        type: "text",
        input_type: "text",
      },
    };
  },
  created() {
    const flow = JSON.parse(JSON.stringify(this.formFields));
    let treatedFlow = [];
    flow.forEach((item) => {
      if (Object.keys(item).length != 0) treatedFlow.push(item);
    });
    this.flow = treatedFlow;
  },
  methods: {
    handlerSaveWebhookToQuestion(idsArray) {
      this.webhookWebhooksIds = idsArray;
      this.$nextTick(() => {
        if (this.$refs.questionForm && this.$refs.questionForm[0])
          this.$refs.questionForm[0].saveWebhookCalls(idsArray);
      });
    },
    openWebhookPopup(webhooksIds, questionId) {
      this.webhookComponentKey += 1;
      this.webhookWebhooksIds = webhooksIds;
      this.webhookQuestionId = questionId;
      this.webhookPopup = true;
      this.webhookVersion = 1;
    },
    getResponseType(question) {
      if (question.input_type !== "assistant") {
        const option = responseOptions.find(
          (option) => option.value === question.input_type
        );
        return option ? option.label : "";
      }
      return question.data.agentName;
    },
    getMessageHeaders(question, getChildrenHeaders) {
      const headers = [];
      headers[0] = [];
      headers[1] = [];
      headers[2] = [];
      const insertIntention = (intent) => {
        if (intent && intent.length > 0) {
          if (this.intentions.indexOf(intent.replace("#", "")) === -1)
            this.intentions.push(intent.replace("#", ""));
        }
      };
      const insertShortcut = (shortcut) => {
        if (shortcut && shortcut.length > 0) {
          if (this.shortcut.indexOf(shortcut.replace("#", "")) === -1)
            this.shortcut.push(shortcut.replace("#", ""));
        }
      };
      const insertEntity = (entity) => {
        if (entity && entity.length > 0) {
          if (this.entities.indexOf(entity.replace("@", "")) === -1)
            this.entities.push(entity.replace("@", ""));
        }
      };
      const searchItems = (questionToSearch) => {
        if (
          questionToSearch.entity &&
          !headers[0].includes(questionToSearch.entity)
        ) {
          headers[0].push(questionToSearch.entity);
          insertEntity(questionToSearch.entity);
        }
        if (
          questionToSearch.intent &&
          !headers[1].includes(questionToSearch.intent)
        ) {
          headers[1].push(questionToSearch.intent);
          insertIntention(questionToSearch.intent);
        }
        if (
          questionToSearch.shortcut &&
          !headers[2].includes(questionToSearch.shortcut)
        ) {
          headers[2].push(questionToSearch.shortcut);
          insertShortcut(questionToSearch.shortcut);
        }
        if (
          getChildrenHeaders &&
          questionToSearch.children &&
          questionToSearch.children.length > 0
        ) {
          questionToSearch.children.forEach((children) => {
            children.data.forEach((childrenData) => {
              if (
                childrenData.entity &&
                !headers[0].includes(childrenData.entity)
              ) {
                headers[0].push(childrenData.entity);
                insertEntity(childrenData.entity);
              }
              if (
                childrenData.intent &&
                !headers[1].includes(childrenData.intent)
              ) {
                headers[1].push(childrenData.intent);
                insertIntention(childrenData.intent);
              }
              if (
                childrenData.shortcut &&
                !headers[2].includes(childrenData.shortcut)
              ) {
                headers[2].push(childrenData.shortcut);
                insertShortcut(childrenData.shortcut);
              }
              if (childrenData.children && childrenData.children.length > 0)
                searchItems(childrenData);
            });
          });
        }
      };
      searchItems(question);
      return headers;
    },

    cancelQuestionEdition() {
      this.edit = "none";
    },
    cancelAgentEdition() {
      this.isAgent = false;
      this.edit = "none";
    },
    cancelSubQuestionEdition() {
      this.edit = "none";
      this.editChildren = "none";
    },
    duplicateQuestion(indexQuestion, indexChildren) {
      const newFlow = this.flow;

      const newQuestion = deepChangeId(
        JSON.parse(JSON.stringify(newFlow[indexQuestion])),
        true
      );
      if (typeof indexChildren === "number") {
        newFlow[indexQuestion].children.splice(
          indexChildren + 1,
          0,
          newQuestion.children[indexChildren]
        );
        newFlow[indexQuestion].suggestions.splice(
          indexChildren + 1,
          0,
          newQuestion.suggestions[indexChildren]
        );
        this.$emit(
          "save-flow",
          newFlow,
          true,
          this.indexQuestion,
          this.indexChildren
        );
      } else {
        newFlow.splice(indexQuestion + 1, 0, newQuestion);
        this.$emit(
          "save-flow",
          newFlow,
          true,
          this.indexQuestion,
          this.indexChildren
        );
      }
    },
    editQuestion(indexQuestion, indexChildren) {
      if (typeof indexChildren === "number") {
        this.editChildren = indexChildren;
        this.edit = indexQuestion;
        this.isAgent = false;
      } else if (this.flow[indexQuestion].input_type === "assistant") {
        this.edit = indexQuestion;
        this.isAgent = true;
      } else {
        this.isAgent = false;
        this.edit = indexQuestion;
        this.editChildren = "none";
      }
    },
    saveQuestion(indexQuestion, newQuestion, custom) {
      const newFlow = this.flow;
      if (typeof indexQuestion != "number") {
        newFlow.push(newQuestion);
      } else {
        newFlow[indexQuestion] = newQuestion;
      }
      this.$emit(
        "save-flow",
        newFlow,
        custom,
        this.indexQuestion,
        this.indexChildren
      );
      this.edit = "none";
    },
    emitSaveFlow(flow) {
      const newFlow = flow.map((question) => {
        if (question.children !== undefined && question.children != 0) {
          question.suggestions = question.children.flatMap((child) => child.id);
          return question;
        } else return question;
      });
      this.$emit(
        "save-flow",
        newFlow,
        true,
        this.indexQuestion,
        this.indexChildren
      );
    },
    saveFlow(flow, custom) {
      let newFlow = flow ? flow : this.flow;
      this.$emit("save-flow", newFlow, custom);
    },
    saveSubflow(subflow, custom, indexQuestion, indexChildren) {
      const newFlow = this.flow;
      newFlow[indexQuestion].children[indexChildren].data = subflow;

      typeof this.indexQuestion === "number"
        ? (indexQuestion = this.indexQuestion)
        : (indexQuestion = null);
      typeof this.indexChildren === "number"
        ? (indexChildren = this.indexChildren)
        : (indexChildren = null);

      if (this.indexArrayPosition != 0)
        this.$emit("save-flow", newFlow, custom, indexQuestion, indexChildren);
      else this.saveFlow(this.flow, custom);
    },
    confirmRemoveQuestion(indexQuestion, indexChildren) {
      const newFlow = this.flow;
      const self = this;
      const removeQuestion = () => {
        if (typeof indexChildren === "number") {
          newFlow[indexQuestion].children.splice(indexChildren, 1);
          newFlow[indexQuestion].suggestions.splice(indexChildren, 1);
        } else newFlow.splice(indexQuestion, 1);
        self.$emit(
          "save-flow",
          newFlow,
          true,
          self.indexQuestion,
          self.indexChildren
        );
      };

      let title;
      let description;

      if (typeof indexChildren === "number") {
        title = "DeleteSubflowTitle";
        description = "ConfirmDeleteSubflow";
      } else {
        title = "DeleteQuestionTitle";
        description = "ConfirmDeleteQuestion";
      }

      this.$vs.dialog({
        id: "question",
        type: "confirm",
        color: "danger",
        title: this.$t(title),
        text: this.$t(description),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: removeQuestion,
      });
    },
    keyupInputSubflowName(e) {
      if (e.keyCode === 13 && e.target.value) {
        e.target.blur();
      }
    },
    blurInputSubflowName(e) {
      if (e.target.value) {
        this.emitSaveFlow(this.flow);
      }
    },
    keyupInputSubflowDescription(question, children) {
      question.suggestionsDescription
        ? (question.suggestionsDescription[children.id] = children.description)
        : (question.suggestionsDescription = {
            [children.id]: children.description,
          });
    },
  },
};
</script>

<style lang="scss">
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
}

.input-onboarding {
  padding-left: 8px;
  font-size: 1rem;
}

.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}

.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}

#iconInfoAvatar span {
  font-size: 14px !important;
}

.floatCounter {
  left: calc(100% - 30px);
  top: 30px;
  color: #999;
}

.assistant-color {
  border-color: rgb(255, 159, 67);
  color: rgb(255, 159, 67);
}
</style>
