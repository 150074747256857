<template>
  <div>
    <!-- Alias Input -->
    <div class="mb-2">
      <label class="block text-sm font-medium text-gray-700">Alias</label>
      <vs-input
        v-model="customAlias"
        class="w-full"
        placeholder="Enter variable alias"
        :disabled="isEditing"
        :danger="Boolean(aliasError)"
        :danger-text="aliasError"
        @input="validateAlias"
      />
    </div>

    <!-- Toggle Selection: JSONPath or Another Request -->
    <div class="mb-2">
      <label class="block text-sm font-medium text-gray-700"
        >Select Input Type</label
      >
      <vs-radio v-model="inputType" vs-value="jsonPath"
        >Create from JSONPath</vs-radio
      >
      <vs-radio v-model="inputType" vs-value="request" class="ml-4"
        >Select from Another Request</vs-radio
      >
    </div>

    <!-- JSONPath Selection -->
    <div v-if="inputType === 'jsonPath'" class="mb-2">
      <label class="block text-sm font-medium text-gray-700"
        >Build JSONPath</label
      >
      <vs-button
        type="border"
        size="small"
        color="primary"
        @click="togglePathBuilder"
      >
        Build Path
      </vs-button>

      <div class="bg-gray-100 mt-2 rounded">
        <strong>Chosen Path:</strong>
        <vs-input v-model="customPath" class="text-sm mt-1 w-full" />
      </div>

      <!-- Path Builder -->
      <custom-path-builder
        v-if="showPathBuilder"
        :json-data="JSONTree"
        @done="handlePathBuilderDone"
      />

      <!-- Preview JSONPath result -->
      <div class="bg-gray-50 p-2 mt-2 rounded" v-if="previewResult">
        <strong>Preview:</strong>
        <pre class="text-sm mt-1">{{ previewResult }}</pre>
      </div>
    </div>

    <!-- Select from Another Request -->
    <div v-else class="mb-2">
      <label class="block text-sm font-medium text-gray-700"
        >Select Request</label
      >
      <vs-select v-model="selectedRequestId" class="w-full">
        <vs-select-item
          v-for="request in filteredRequests"
          :key="request._id"
          :value="request._id"
          :text="request.name"
        />
      </vs-select>

      <!-- Select Variable from Chosen Request -->
      <div v-if="selectedRequestId" class="mt-2">
        <label class="block text-sm font-medium text-gray-700"
          >Select Variable</label
        >
        <vs-select v-model="selectedVariable" class="w-full">
          <vs-select-item
            v-for="(value, key) in getMappedVariables(selectedRequestId)"
            :key="key"
            :value="key"
            :text="`${key} (${value})`"
            :class="{ hidden: key === 'mappedVariablesTransformation' }"
          />
        </vs-select>
      </div>
    </div>

    <!-- Transformation Tabs -->
    <vs-card class="p-4 shadow-sm rounded-lg bg-white">
      <div slot="header"><h5>Transformations</h5></div>
      <vs-radio v-model="transformationType" vs-value="regex"
        >Regex Transformation</vs-radio
      >
      <vs-radio class="ml-4" v-model="transformationType" vs-value="js"
        >JavaScript Function (Beta)</vs-radio
      >
      <div label="Regex Transformation" v-if="transformationType === 'regex'">
        <div class="mt-6">
          <vs-button
            class="mb-2"
            color="success"
            size="small"
            @click="addRegexTransformation"
            >+ Add Transformation</vs-button
          >
          <div
            v-for="(regex, index) in transformations.regex"
            :key="index"
            class="mb-2 flex items-center space-x-2"
          >
            <vs-input
              v-model="regex.pattern"
              placeholder="Enter regex pattern"
            />
            <vs-input
              v-model="regex.replacement"
              placeholder="Enter replacement string"
            />
            <vs-button
              size="small"
              color="danger"
              icon="remove"
              @click="removeRegexTransformation(index)"
            />
          </div>
          <vs-button
            v-if="transformations.regex.length > 0"
            class="mt-2"
            color="primary"
            size="small"
            @click="applyTransformation"
          >
            {{ $t("Test") }}
          </vs-button>
        </div>
      </div>
      <div
        label="JavaScript Function (Beta)"
        @click="setupCodeMirror()"
        v-if="transformationType === 'js'"
      >
        <div class="relative">
          <div class="w-full bg-gray-100 font-mono overflow-hidden">
            <!-- Header with Function Declaration -->
            <div class="bg-gray-200 text-gray-900 border-b border-gray-300">
              <p class="text-sm mt-2">
                For it to work, the function must be named
                <strong>transformation</strong> and accept a single argument
                <strong>value</strong>. Example:
                <i class="bg-primary text-white"
                  >function transformation(value) { }</i
                >
              </p>
            </div>

            <div class="border-4 border-solid border-grey-light mt-2">
              <codemirror
                ref="editor"
                v-model="transformations.js"
                :options="editorOptions"
                class="h-40"
              ></codemirror>
            </div>
          </div>
        </div>

        <div
          class="relative mt-0 p-3 bg-primary-light text-black border-4 border-solid border-grey-light border-t-0 overflow-auto h-40 font-mono"
        >
          <div>
            <span class="text-lg mb-0">Console</span>
            <vs-divider class="mt-0" />
          </div>
          <pre class="text-sm mt-1">
<span v-for="(log, index) in consoleLogs" :key="index">{{ log }}<br></span>
  </pre>

          <!-- Bottom attached div -->
          <div
            class="absolute bottom-0 left-0 w-full bg-grey-light p-2 text-black text-center"
          >
            Transformation result: {{ transformedValues }}
          </div>
        </div>

        <vs-button
          class="mt-2"
          color="primary"
          size="small"
          @click="applyTransformation"
        >
          {{ $t("Test") }}
        </vs-button>
      </div>
    </vs-card>

    <!-- Transformed Value Display -->
    <div class="mt-4" v-if="transformedValues">
      <strong>Transformed Preview: </strong>
      <span class="text-success">{{ transformedValues }}</span>
    </div>

    <!-- Save/Cancel Buttons -->
    <div class="mt-6 flex justify-end">
      <vs-button
        size="small"
        color="primary"
        :disabled="Boolean(aliasError) || !customAlias || !customPath"
        @click="saveCustomVariable"
      >
        {{ isEditing ? "Update" : "Save" }}
      </vs-button>
      <vs-button
        size="small"
        type="border"
        color="danger"
        class="ml-2"
        @click="cancelCustomVariable"
      >
        Cancel
      </vs-button>
    </div>
  </div>
</template>

<script>
import CustomPathBuilder from "./CustomPathBuilder.vue";
import { JSONPath } from "jsonpath-plus";

// Import CodeMirror core & required dependencies
import "codemirror/lib/codemirror.css"; // Base styles
import "codemirror/theme/eclipse.css"; // Theme for better visibility
import "codemirror/mode/javascript/javascript"; // JavaScript Mode
import "codemirror/addon/edit/closebrackets"; // Auto-close brackets
import "codemirror/addon/edit/matchbrackets"; // Highlight matching brackets
import "codemirror/addon/comment/comment"; // Enable code comments
import "codemirror/addon/fold/foldcode"; // Code folding
import "codemirror/addon/fold/foldgutter"; // Code folding UI
import "codemirror/addon/fold/brace-fold"; // Fold `{}` blocks

export default {
  name: "VariableMappingBuilder",
  components: { CustomPathBuilder },
  props: {
    requestName: { type: String, required: false },
    JSONTree: { type: Array || Object, required: true },
    existingVariables: { type: Object, default: () => ({}) },
    editVariable: { type: Object, default: null },
    requestList: { type: Array, default: () => [] },
  },
  data() {
    return {
      inputType: "jsonPath",
      selectedRequestId: "",
      selectedVariable: "",
      mappedVariables: {},
      customAlias: "",
      customPath: "",
      aliasError: "",
      showPathBuilder: false,
      previewResult: "",
      isEditing: false,
      transformedValues: undefined,
      validationResults: false,
      variableValue: "",
      regexErrors: [],
      consoleLogs: [],
      editorOptions: {
        mode: "javascript",
        theme: "eclipse",
        lineNumbers: true,
        tabSize: 2,
        indentWithTabs: false,
        smartIndent: true,
      },
      jsEnabled: false,
      regexEnabled: false,
      functionBody: "",
      transformations: { js: "", regex: [] },
      transformationType: "",
    };
  },
  computed: {
    filteredRequests() {
      // find index
      const currentIndex = this.requestList.findIndex(
        (req) => req.name === this.requestName
      );
      // se -1, retun all requests
      if (currentIndex === -1) return this.requestList;
      // or requests that come BEFORE the current request
      return this.requestList.slice(0, currentIndex);
    },
  },
  watch: {
    transformationType(val) {
      if (val === "regex" && this.transformations.js.length > 0) {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: `Confirm`,
          text: "If you have a JS transformation, it will be cleared. Do you want to continue?",
          confirm: () => {
            this.transformations.js = "";
          },
          cancel: () => {
            this.transformationType = "js";
          },
        });
      } else if (val === "js" && this.transformations.regex.length > 0) {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: `Confirm`,
          text: "If you have a Regex transformation, it will be cleared. Do you want to continue?",
          confirm: () => {
            this.transformations.regex = "";
          },
          cancel: () => {
            this.transformationType = "regex";
          },
        });
      }
    },
    customPath(newPath) {
      if (this.inputType === "jsonPath") {
        this.previewJsonPath(newPath);
      }
    },
    selectedVariable(newVar) {
      if (this.selectedRequestId && newVar) {
        const request = this.requestList.find(
          (req) => req._id === this.selectedRequestId
        );
        if (request) {
          this.customPath = `[[${request.name}]].${newVar}`;
        }
      }
    },
  },
  mounted() {
    if (this.editVariable) {
      this.customAlias = this.editVariable.alias;
      this.customPath = this.editVariable.path;
      this.isEditing = true;

      this.transformations = this.editVariable.transformations || {
        js: "",
        regex: [],
      };

      if (this.transformations.js.length > 0) this.transformationType = "js";
      else if (this.transformations.regex.length > 0)
        this.transformationType = "regex";

      if (this.customPath.startsWith("[[")) {
        const match = this.customPath.match(/^\[\[(.*?)\]\]\.(.*)/);
        if (match) {
          const foundRequest = this.requestList.find(
            (req) => req.name === match[1]
          );
          this.selectedRequestId = foundRequest ? foundRequest._id : "";
          this.selectedVariable = match[2];
          this.inputType = "request";
        }
      }
    }
  },
  methods: {
    setupCodeMirror() {
      if (this.transformations.js.length > 0) return;
      this.$nextTick(() => {
        const editor = this.$refs.editor.codemirror;

        // Insert fixed function header and footer
        editor.setValue("function transformation(value) {\n\n\n}");

        // Lock the first and last line
        editor.markText(
          { line: 0, ch: 0 },
          { line: 0, ch: editor.getLine(0).length },
          { readOnly: true }
        );
        editor.markText(
          { line: editor.lineCount() - 1, ch: 0 },
          {
            line: editor.lineCount() - 1,
            ch: editor.getLine(editor.lineCount() - 1).length,
          },
          { readOnly: true }
        );

        // Move cursor to the editable area
        editor.setCursor({ line: 1, ch: 0 });
      });
    },
    togglePathBuilder() {
      this.showPathBuilder = !this.showPathBuilder;
    },
    enableJsTransformation() {
      this.jsEnabled = !this.jsEnabled;
    },
    enableRegexTransformation() {
      this.regexEnabled = !this.regexEnabled;
    },
    addRegexTransformation() {
      this.transformations.regex.push({ pattern: "", replacement: "" });
    },
    removeRegexTransformation(index) {
      this.transformations.regex.splice(index, 1);
    },
    applyTransformation() {
      let value = String(this.variableValue);

      if (this.transformations.regex.length > 0) {
        this.transformations.regex.forEach(({ pattern, replacement }) => {
          value = value.replace(new RegExp(pattern, "g"), replacement);
        });

        this.transformedValues = value;
      }

      this.applyJsTransformation();
    },
    handlePathBuilderDone(finalPath) {
      if (finalPath) this.customPath = finalPath;
      this.showPathBuilder = false;
    },
    previewJsonPath(pathString) {
      this.previewResult = "";
      if (!pathString) {
        this.previewResult = "Select or build a path to preview.";
        return;
      }
      try {
        const result = JSONPath({ path: pathString, json: this.JSONTree });
        this.previewResult = JSON.stringify(result, null, 2);
        this.variableValue =
          Array.isArray(result) && result.length > 0
            ? result[0]
            : `"{${this.customAlias}}"`;
      } catch (err) {
        this.previewResult = `Error: ${err.message}`;
      }
    },
    validateAlias() {
      this.aliasError = "";
      if (!this.customAlias) return;

      const aliasPattern = /^[a-zA-Z0-9_-]+$/;
      if (!aliasPattern.test(this.customAlias)) {
        this.aliasError =
          "Alias can only contain letters, numbers, dashes (-), and underscores (_).";
        return;
      }

      const allAliases = Object.keys({
        ...this.mappedVariables,
        ...this.existingVariables,
      });

      if (!this.isEditing && allAliases.includes(this.customAlias)) {
        this.aliasError = `Alias "${this.customAlias}" already exists.`;
      }
    },
    saveCustomVariable() {
      this.validateAlias();
      if (this.aliasError || !this.customAlias || !this.customPath) return;

      if (!this.mappedVariables.mappedVariablesTransformation) {
        this.mappedVariables.mappedVariablesTransformation = {};
      }

      this.mappedVariables.mappedVariablesTransformation[this.customAlias] = {
        js: this.transformations.js, // Store the JavaScript function
        regex: this.transformations.regex, // Store regex transformations
      };

      if (this.isEditing) {
        this.$emit("update", {
          alias: this.customAlias,
          path: this.customPath,
          transformations: this.transformations,
        });
      } else {
        this.$set(this.mappedVariables, this.customAlias, this.customPath);
        this.$emit("save", this.mappedVariables);
      }
      this.resetForm();
    },
    cancelCustomVariable() {
      this.resetForm();
      this.$emit("cancel");
    },
    resetForm() {
      this.inputType = "jsonPath";
      this.selectedRequestId = "";
      this.selectedVariable = "";
      this.customAlias = "";
      this.customPath = "";
      this.aliasError = "";
      this.previewResult = "";
      this.showPathBuilder = false;
      this.isEditing = false;
      this.transformedValues = "";
      this.variableValue = "";
      this.validationResults = false;
    },
    getMappedVariables(requestId) {
      const request = this.requestList.find((req) => req._id === requestId);
      return request ? request.mappedVariables || {} : {};
    },
    applyJsTransformation() {
      try {
        this.consoleLogs = []; // Clear logs before each run

        const functionCode = this.transformations.js.trim();
        if (functionCode.length === 0) return;

        // Validate function format
        const functionPrefix = "function transformation(value) {";
        const functionSuffix = "}";
        if (
          !functionCode.startsWith(functionPrefix) ||
          !functionCode.endsWith(functionSuffix)
        ) {
          this.consoleLogs.push(
            "Error: Function must start with 'function transformation(value) {' and end with '}'"
          );
          return;
        }

        const functionBody = functionCode
          .substring(
            functionPrefix.length,
            functionCode.length - functionSuffix.length
          )
          .trim();
        this.functionBody = functionBody;

        const forbiddenPatterns = [
          /window/i,
          /document/i,
          /global/i,
          /process/i,
          /require/i,
          /import/i,
          /fetch/i,
          /XMLHttpRequest/i,
          /setTimeout/i,
          /setInterval/i,
          /eval/i,
          /Function/i,
          /execScript/i,
        ];

        if (forbiddenPatterns.some((pattern) => pattern.test(functionBody))) {
          this.consoleLogs.push(
            "Error: Forbidden operations or words detected in your function. Prohibit: window, document, global, process, require, import, fetch, XMLHttpRequest, setTimeout, setInterval, eval, Function, execScript"
          );
          return;
        }

        const sandboxConsole = {
          log: (...args) => {
            this.consoleLogs.push(
              "[" +
                new Date().toLocaleTimeString() +
                "] " +
                args.map(String).join(" ")
            );
          },
        };

        const wrappedFunction = new Function(
          "console",
          "Buffer",
          "JSON",
          "value",
          `
      ${functionCode}
      return transformation(value);
      `
        );

        this.transformedValues = wrappedFunction(
          sandboxConsole,
          Buffer,
          JSON,
          this.variableValue
        );
      } catch (error) {
        this.consoleLogs.push(
          "[" + new Date().toLocaleTimeString() + "] Error: " + error.message
        );
      }
    },
  },
};
</script>
