<!-- =========================================================================================
    File Name: FlowWebhookV2.vue
========================================================================================== -->

<template>
  <div style="height: 100%">
    <div v-if="!showRequestForm">
      <div class="w-full flex">
        <vs-button color="success" size="small" @click="createNew()">
          + Nova Requisição
        </vs-button>

        <vs-select
          v-model="selectedRequest"
          label="Search existing requests"
          class="ml-3"
          :autocomplete="true"
          :filter="true"
          @input-change="(event) => searchRequests(event.target.value)"
        >
          <vs-select-item
            v-for="item in searchResults"
            :key="item._id"
            :value="item._id"
            :text="item.name"
          >
            {{ item.name }}
          </vs-select-item>
        </vs-select>
        <div v-if="isSearching" class="text-grey-500 text-center ml-3 mt-6">
          <div class="flex flex-col items-center justify-center text-gray-500">
            <i class="material-icons animate-spin text-sm text-gray-400"
              >autorenew</i
            >
          </div>
        </div>
      </div>

      <vs-list>
        <vs-list-header title="Requests List" />
        <vs-list-item
          v-for="(item, index) in requestList"
          :key="`${index}_${item._id}`"
          :title="`${index + 1} - ${item.name}`"
          :subtitle="item.request.url"
        >
          <div class="flex">
            <!-- Edit and Delete Buttons -->
            <vs-button
              color="primary"
              icon-pack="feather"
              icon="icon-edit"
              @click="editRequest(item._id)"
            />
            <vs-button
              class="ml-3"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
              @click="deleteRequestConfirm(item._id)"
            />

            <vs-button
              class="ml-3"
              color="success"
              icon-pack="feather"
              icon="icon-arrow-up"
              :disabled="index === 0"
              @click="moveItem(index, 'up')"
            />
            <vs-button
              class="ml-3"
              color="warning"
              icon-pack="feather"
              icon="icon-arrow-down"
              :disabled="index === requestList.length - 1"
              @click="moveItem(index, 'down')"
            />
          </div>
        </vs-list-item>
      </vs-list>
    </div>
    <form autocomplete="off" id="whForm" v-if="showRequestForm">
      <div class="mb-4">
        <h4 class="flex">
          <feather-icon
            class="cursor-pointer mr-4"
            icon="ArrowLeftIcon"
            @click.stop="reset()"
          />Requisição
        </h4>
        <vs-input
          v-model="requestName"
          class="w-1/2"
          required
          type="text"
          label="Nome da Requisição (somente numeros, letras e hifen)"
          @input="filterInput"
        />
        <vs-input
          v-model="requestDescription"
          class="w-1/2"
          required
          type="text"
          label="Description"
        />
      </div>
      <div class="mb-4 flex">
        <v-select
          class="w-32 flex-none"
          data-method
          id="data-method"
          :options="['GET', 'POST', 'PUT', 'PATCH', 'DELETE']"
          v-model="request.method"
        >
        </v-select>

        <vs-input
          v-model="url"
          class="flex-initial w-full ml-3 mr-3"
          required
          type="url"
          placeholder="htttps://www.example.com"
        />
      </div>

      <vs-tabs v-model="tabIndex">
        <vs-tab
          v-for="(item, index) in tabs"
          :key="index"
          :label="getTabTitle(index)"
          :disabled="request.method === 'GET' && getTabTitle(index) === 'Body'"
        >
          <div>
            <ul class="flex" v-if="item === 'data'">
              <li>
                <vs-radio
                  v-model="requestBodyDataType"
                  vs-name="radios1"
                  vs-value="formdata"
                  >Form-Data</vs-radio
                >
              </li>
              <li class="ml-4">
                <vs-radio
                  v-model="requestBodyDataType"
                  vs-name="radios1"
                  vs-value="json"
                  >Json</vs-radio
                >
              </li>
            </ul>
            <vs-list :key="componentKey">
              <vs-list-header
                title="Basic Auth"
                v-if="item === 'headers'"
              ></vs-list-header>
              <vs-list-item v-if="item === 'headers'">
                <template slot="avatar">
                  <vs-input
                    name="auth_username"
                    type="text"
                    autocomplete="off"
                    class="flex-1 mr-1"
                    placeholder="User"
                    v-model="request.auth.username"
                  />
                  <vs-input
                    name="auth_pass"
                    autocomplete="off"
                    type="text"
                    class="flex-1"
                    placeholder="Password"
                    v-model="request.auth.password"
                  />
                </template>
              </vs-list-item>
              <vs-list-header title="Structure"></vs-list-header>
              <vs-list-item
                v-for="(item2, index2) in request[item]"
                :key="index2"
              >
                <template slot="avatar">
                  <div class="w-full flex">
                    <vs-input :value="index2" disabled class="flex-none mr-1" />
                    <vs-input :value="item2" disabled class="flex-1 mr-1" />
                    <vs-button
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click="unsetParam(item, index2)"
                    >
                    </vs-button>
                  </div>
                </template>
              </vs-list-item>
            </vs-list>
            <vs-button
              color="primary"
              icon-pack="feather"
              size="small"
              class="mt-2 btn btn-outline-success mr-3"
              @click="addParam(item, 'custom')"
            >
              Add Parâmetro
            </vs-button>
            <vs-button
              color="primary"
              icon-pack="feather"
              size="small"
              class="mt-2 btn btn-outline-success mr-3"
              @click="addParam(item, 'otherRequest')"
            >
              Add Variable
            </vs-button>
          </div>
        </vs-tab>
      </vs-tabs>
      <div ref="dataKeyValueTemplate" class="hidden">
        <div class="flex my-2">
          <vs-input
            type="text"
            data-key
            id="data-key"
            class="flex-none mr-1"
            placeholder="Key"
            ref="paramKey"
            v-model="paramKey"
          />
          <vs-select
            v-model="paramOtherRequest"
            class="flex-1 mr-1"
            ref="paramOtherRequest"
          >
            <vs-select-item
              v-for="(path, alias) in mappedVariables"
              :key="`otherParam_select_${alias}`"
              :value="alias"
              :text="alias"
              :class="{ hidden: alias === 'mappedVariablesTransformation' }"
            />
          </vs-select>
          <vs-input
            id="data-value"
            type="text"
            data-value
            class="flex-1 mr-1"
            placeholder="Value"
            ref="paramCustom"
            v-model="paramCustom"
          />
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-plus"
            :disabled="!validatedParam"
            @click="setParam()"
          >
          </vs-button>
        </div>
      </div>
      <div class="mt-4" v-if="responseTest">
        <vs-textarea
          label="Retorno"
          v-model="responseTest"
          disabled
          class="w-full mt-2"
        />
      </div>

      <vs-divider />

      <div class="mt-4">
        <h4>Testar</h4>
        <div class="w-full">
          <p>
            Informe os valores das entidades necessarias para testar a
            requisição
          </p>
          <li class="flex flex-wrap">
            <ul v-for="(item, index) in entities" :key="index">
              {{
                item
              }}:
              <vs-input
                name="auth_username"
                type="text"
                autocomplete="off"
                class="flex-1 mr-1"
                v-model="entitiesTest[`${item}`]"
              />
            </ul>
          </li>
          <div class="mt-1">
            <vs-input
              name="timeout"
              icon-after="true"
              icon="access_time_filled"
              type="text"
              v-model="timeout"
              label="Timeout (ms)"
            />
          </div>
          <vs-button
            class="mt-4"
            :disabled="!validatedRequest"
            @click="testRequest()"
            size="small"
          >
            {{ $t("Test") }}</vs-button
          >
        </div>

        <vs-card class="p-4 mt-4" v-if="testResponseDataRaw">
          <p>
            Response code: {{ testResponseStatus }}
            <span class="text-danger" v-if="testJSErrors">
              [JS errors occurred]</span
            >
          </p>
          <div class="relative">
            <div
              v-if="testResponseStatus"
              :class="[
                'overflow-hidden transition-all relative',
                {
                  'h-24': !testResponseStatusExpanded,
                  'h-auto': testResponseStatusExpanded,
                },
              ]"
            >
              <div>
                <pre
                  ref="testResponseDataRaw"
                  class="text-sm text-gray-800 whitespace-pre-wrap flex-1"
                  >{{ testResponseDataRaw }}</pre
                >
                <pre
                  ref="testJSErrors"
                  class="text-sm text-gray-800 whitespace-pre-wrap flex-1"
                  >{{ testJSErrors }}</pre
                >
              </div>
              <div
                v-if="testResponseIsExpandable && !testResponseStatusExpanded"
                class="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-white to-transparent"
              ></div>
            </div>

            <vs-button
              v-if="testResponseIsExpandable"
              class="absolute bottom left-1/2 transform -translate-x-1/2 z-10 shadow-lg"
              @click="testResponseStatusExpanded = !testResponseStatusExpanded"
              size="small"
            >
              {{ testResponseStatusExpanded ? "Collapse" : "Expand" }}
            </vs-button>
          </div>
        </vs-card>

        <vs-divider />

        <h4 class="flex">
          <span>Variables</span>
          <vs-button
            class="ml-1"
            type="border"
            icon="add"
            size="small"
            @click="showVariableMapping = !showVariableMapping"
          />
        </h4>
        <div class="mt-2">
          <vs-card
            v-if="Object.keys(mappedVariables).length && !showVariableMapping"
            class="mt-2 mb-2"
          >
            <div slot="header">Created Variables</div>
            <div class="flex flex-wrap gap-2">
              <vs-chip
                v-for="(path, alias) in mappedVariables"
                :key="`${path}_${alias}`"
                closable
                @click="variableDeleteConfirm(alias)"
                :class="{ hidden: alias === 'mappedVariablesTransformation' }"
              >
                <vs-avatar
                  icon="edit"
                  size="20px"
                  @click="variableEdit(alias)"
                />
                {{ alias }}
              </vs-chip>
            </div>
          </vs-card>

          <vs-card class="w-1/2" v-if="showVariableMapping">
            <div slot="header">
              <div class="flex items-center justify-between w-full">
                <span>Variables Mapping Builder</span>

                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="showVariableMapping = false"
                />
              </div>
            </div>
            <flow-variable-mapping-builder
              class="mt-2"
              :requestName="requestName"
              :requestList="requestList"
              :JSONTree="parsedTestResponseDataRaw || {}"
              :existingVariables="mappedVariables"
              :editVariable="editVariable"
              @save="handleSaveMappedVariables"
              @update="handleUpdateMappedVariable"
              @cancel="handleCancelMappedVariable"
            />
          </vs-card>
        </div>
      </div>

      <vs-divider />

      <div class="mt-4">
        <h4 class="mb-2">
          Ações
          <vs-dropdown style="z-index: 999999" vs-trigger-click>
            <vs-button type="border" icon="add" size="small" />
            <vs-dropdown-menu style="z-index: 999990">
              <vs-dropdown-item @click="newAction('setEntity')">
                Set Entity
              </vs-dropdown-item>
              <vs-dropdown-item @click="newAction('sendMessage')">
                Send Message
              </vs-dropdown-item>
              <vs-dropdown-item @click="newAction('setOperator')">
                Set Operator
              </vs-dropdown-item>
              <vs-dropdown-item @click="newAction('forceQuestion')">
                Force Question
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </h4>
        <vs-card class="cardx" v-if="showCreateOrEditAction">
          <div slot="header">
            <div class="flex items-center justify-between w-full">
              <span
                class="font-semibold text-small"
                v-if="currentActionEditIndex > -1"
                >{{ $t("EditAction") }} [{{ $t(showCreateOrEditAction) }}]
              </span>
              <span class="font-semibold text-small" v-else
                >{{ $t("NewAction") }} [{{ $t(showCreateOrEditAction) }}]
              </span>

              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-x"
                @click="showCreateOrEditAction = false"
              />
            </div>
          </div>
          <div>
            <flow-variables-conditions
              :mappedVariables="mappedVariables"
              :conditions="currentActionData.conditions || []"
              @save="handleSaveVariablesConditions"
            />
            <div
              class="flex my-2 mt-3 items-center"
              v-if="showCreateOrEditAction === 'setEntity'"
            >
              <vs-input
                label="Entity"
                type="text"
                icon="alternate_email"
                class="flex-none mr-1"
                v-model="currentActionData.key"
              />
              <span class="flex-none mr-1 pt-4"> = </span>
              <vs-select
                autocomplete
                v-model="currentActionData.variable"
                label="Variable"
              >
                <vs-select-item
                  :key="`option_select_${alias}`"
                  :value="alias"
                  :text="alias"
                  v-for="(path, alias) in mappedVariables"
                  :class="{ hidden: alias === 'mappedVariablesTransformation' }"
                />
              </vs-select>
              <vs-button
                class="ml-3 mt-4"
                color="success"
                :icon="currentActionEditIndex > -1 ? 'done' : 'add'"
                :disabled="
                  !currentActionData.key || !currentActionData.variable
                "
                @click="saveAction('setEntity')"
              ></vs-button>
            </div>
            <div v-if="showCreateOrEditAction === 'sendMessage'" class="mt-3">
              <flow-compose-message
                :mappedVariables="mappedVariables"
                :editIndex="currentActionEditIndex"
                :editMessage="currentActionData"
                @save="saveAction"
              />
            </div>
            <div
              class="flex my-2 mt-3"
              v-if="showCreateOrEditAction === 'setOperator'"
            >
              <vs-select autocomplete v-model="currentActionData.variable">
                <vs-select-item
                  :key="`option_select_${alias}`"
                  :value="alias"
                  :text="alias"
                  v-for="(path, alias) in mappedVariables"
                  :class="{ hidden: alias === 'mappedVariablesTransformation' }"
                />
              </vs-select>
              <vs-button
                class="ml-3"
                color="success"
                :icon="currentActionEditIndex > -1 ? 'done' : 'add'"
                :disabled="!currentActionData.variable"
                @click="saveAction('setOperator')"
              ></vs-button>
            </div>
            <div v-if="showCreateOrEditAction === 'forceQuestion'">
              <div class="flex my-2 mt-3">
                <vs-select
                  type="text"
                  class="flex-1 ml-2 mr-2"
                  label="Question to force"
                  v-model="currentActionData.forceQuestion"
                >
                  <vs-select-item
                    :key="`option_select_${index}`"
                    :value="item.value"
                    :text="item.label"
                    v-for="(item, index) in labelsWithIndexes"
                  />
                </vs-select>
                <vs-button
                  class="mt-5"
                  color="success"
                  :disabled="!currentActionData.forceQuestion"
                  :icon="currentActionEditIndex > -1 ? 'done' : 'add'"
                  @click="saveAction('forceQuestion')"
                />
              </div>
            </div>
          </div>
        </vs-card>

        <!-- NEW API CALLS VERSION -->
        <div class="flex flex-wrap">
          <div
            v-for="(items, group) in actions"
            :key="group"
            class="w-1/5 mr-3"
            :class="{ hidden: !actions[group].length }"
          >
            <vs-card class="mb-4">
              <div slot="header" class="text-base">
                {{ $t(group) }}
              </div>
              <div>
                <ul class="w-full">
                  <li
                    v-for="(item, index) in items"
                    :key="`${group}-${index}`"
                    class="flex items-center justify-between bg-primary-light rounded-md p-2 mb-2"
                  >
                    <div class="truncate mr-2">
                      <span class="text-base" v-if="group === 'sendMessage'">
                        <vs-tooltip :text="item.messageTemplate">
                          {{ item.messageTemplate }}
                        </vs-tooltip>
                      </span>
                      <span class="text-base" v-else-if="group === 'setEntity'">
                        @{{ item.key }}: { {{ item.variable }} }
                      </span>
                      <span
                        class="text-base"
                        v-else-if="group === 'forceQuestion'"
                      >
                        {{ getForceQuestionLabel(item.forceQuestion) }}
                      </span>
                      <span
                        class="text-base"
                        v-else-if="group === 'setOperator'"
                      >
                        Set if operator email = { {{ item.variable }} }
                      </span>
                    </div>
                    <div class="flex-none w-14 flex space-x-2">
                      <vs-icon
                        v-if="index > 0 && group === 'sendMessage'"
                        class="mt-2 text-lg cursor-pointer"
                        icon="arrow_circle_up"
                        color="primary"
                        @click="moveActionItem(group, index, -1)"
                      />
                      <vs-icon
                        v-if="
                          index < items.length - 1 && group === 'sendMessage'
                        "
                        class="mt-2 text-lg cursor-pointer"
                        icon="arrow_circle_down"
                        color="primary"
                        @click="moveActionItem(group, index, 1)"
                      />
                      <vs-icon
                        class="mt-2 text-lg cursor-pointer"
                        icon="edit"
                        color="primary"
                        @click="editAction(index, item, group)"
                      />
                      <vs-icon
                        class="mt-2 text-lg text-danger cursor-pointer"
                        icon="delete"
                        @click="confirmActionDelete(group, index)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </vs-card>
          </div>
        </div>
      </div>

      <vs-divider v-if="testResponseDataRaw" />

      <div class="mt-4" v-if="testResponseDataRaw">
        <h4 class="mb-2 flex-none">Final Test Results</h4>
        <div class="flex flex-wrap">
          <div
            v-for="(items, group) in actionsToExecute"
            :key="group"
            class="w-1/5 mr-3"
            :class="{ hidden: !actionsToExecute[group].length }"
          >
            <vs-card class="mb-4">
              <div slot="header" class="text-base">
                {{ $t(group) }}
              </div>
              <div>
                <ul class="w-full">
                  <li
                    v-for="(item, index) in items"
                    :key="`${group}-${index}`"
                    class="flex items-center justify-between bg-primary-light rounded-md p-2 mb-2"
                  >
                    <div class="truncate mr-2">
                      <div v-if="group === 'sendMessage'">
                        <span class="text-base">
                          <vs-tooltip :text="item.messageTemplate">
                            {{ item.messageTemplate }}
                          </vs-tooltip>
                        </span>
                        <div v-if="item.suggestions.length" class="mt-2">
                          <div>Buttons:</div>
                          <vs-chip
                            v-for="(suggestion, index) in item.suggestions"
                            :key="suggestion + index"
                            class="mr-1"
                            color="primary"
                            >{{ suggestion }}</vs-chip
                          >
                        </div>
                      </div>
                      <span class="text-base" v-else-if="group === 'setEntity'">
                        {{ item.key }}: {{ item.variable }}
                      </span>
                      <span
                        class="text-base"
                        v-else-if="group === 'forceQuestion'"
                      >
                        Set question:
                        {{ getForceQuestionLabel(item.forceQuestion) }}
                      </span>
                      <span
                        class="text-base"
                        v-else-if="group === 'setOperator'"
                      >
                        Set if operator email { {{ item.variable }} } =
                        {{ item.value }}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </vs-card>
          </div>
        </div>
      </div>

      <vs-divider />

      <vs-button
        color="success"
        class="mt-4"
        :disabled="!validatedRequest"
        @click="saveRequest()"
      >
        {{ $t("Save") }}</vs-button
      >
    </form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import FlowComposeMessage from "./components/FlowComposeMessage.vue";
import VariableMappingBuilder from "./components/VariableMappingBuilder.vue";
import FlowVariablesConditions from "./components/FlowVariablesConditions.vue";

export default {
  name: "FlowWebhookV2",
  components: {
    "v-select": vSelect,
    "flow-compose-message": FlowComposeMessage,
    "flow-variable-mapping-builder": VariableMappingBuilder,
    "flow-variables-conditions": FlowVariablesConditions,
  },
  props: {
    webhookWebhooksIds: {
      type: Array,
    },
    intentions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isSearching: false,
      searchTimeout: null,
      actions: {
        setEntity: [],
        sendMessage: [],
        setOperator: [],
        forceQuestion: [],
      },
      mappedVariables: {},
      editVariable: null,
      showVariableMapping: false,
      timeout: 20000,
      entities: [],
      showRequestForm: false,
      requestList: [],
      selectedRequest: null,
      searchQuery: "",
      searchResults: [],
      tabs: ["headers", "params", "data"],
      componentKey: 0,
      tabIndex: 0,
      form: null,
      responseTest: "",
      entitiesTest: {},

      testResponseStatus: null,
      testResponseStatusExpanded: false,
      testResponseIsExpandable: false,
      testResponseParsedResponse: {},
      testResponseData: "",
      testResponseDataRaw: "",
      testJSErrors: "",

      destination: null,
      paramType: null,
      paramKey: "",
      paramEntity: "",
      paramCustom: "",
      paramOtherRequest: "",

      requestId: "",
      requestName: "",
      requestDescription: "",
      requestStatus: 1,
      requestBodyDataType: "json",
      url: "",
      request: {
        url: "",
        method: "GET",
        headers: {},
        params: {},
        data: {},
        auth: {
          username: null,
          password: null,
        },
        responseType: "json",
      },
      showCreateOrEditAction: false,
      currentActionData: {},
      currentActionEditIndex: -1,
      actionsToExecute: {
        setEntity: [],
        sendMessage: [],
        setOperator: [],
        forceQuestion: [],
      },

      saveTimeout: null,
    };
  },
  mounted() {
    this.checkTestResponseExpandable();
    this.fetchRequests();
  },
  watch: {
    selectedRequest(val) {
      if (val) {
        const item = this.searchResults.find((item) => item._id === val);
        this.addExistingRequest(item);
      }
    },
    showVariableMapping(val) {
      if (!val) this.editVariable = null;
    },
    testResponseDataRaw() {
      this.checkTestResponseExpandable();
    },
    showCreateOrEditAction(val) {
      if (!val) {
        this.currentActionData = {};
        this.currentActionEditIndex = -1;
      }
    },
    url(val, oldVal) {
      this.request.url = val;

      const currentMatches = val.match(/@[\w_-]+/g) || [];
      const oldMatches = oldVal.match(/@[\w_-]+/g) || [];

      let entities = [
        ...this.entities,
        ...currentMatches.filter((match) => !this.entities.includes(match)),
      ];

      entities = entities.filter(
        (entity) =>
          !oldMatches.includes(entity) || currentMatches.includes(entity)
      );
      this.extractEntities(entities);
    },
    requestId() {
      this.hideParams();
      this.resetParams();
      this.componentKey += 1;
    },
    showRequestForm(val) {
      const thisIns = this;
      setTimeout(() => {
        if (val) {
          thisIns.hideParams();
          thisIns.resetParams();
          thisIns.componentKey += 1;
        }
      }, 800);
    },
    tabIndex() {
      this.hideParams();
      this.resetParams();
      this.componentKey += 1;
    },
  },
  computed: {
    NoRecordsFounded: function () {
      return this.isSearching
        ? `${this.$t("Searching")}...`
        : `${this.$t("NoRecordsFounded")}`;
    },
    parsedTestResponseDataRaw: function () {
      try {
        return JSON.parse(this.testResponseDataRaw);
      } catch (e) {
        return {};
      }
    },
    formFields2: function () {
      return this.$store.state.chatbotManagement.current_chatbot.formFields2;
    },
    labelsWithIndexes: function () {
      return this.formFields2.map((node, index) => ({
        label: node.label || `Mensagem sem título. Mensagem número ${index}`,
        value: node.id,
      }));
    },
    otherRequestOptions: function () {
      // find index
      const currentIndex = this.requestList.findIndex(
        (req) => req.name === this.requestName
      );
      // se -1, retun all requests
      if (currentIndex === -1) return this.requestList;
      // or requests that come BEFORE the current request
      return this.requestList.slice(0, currentIndex);
    },
    validatedRequest: function () {
      return (
        this.request.url !== "" &&
        this.request.method !== "" &&
        this.requestName !== ""
      );
    },
    validatedParam: function () {
      return (
        this.paramKey !== "" &&
        (this.paramEntity !== "" ||
          this.paramCustom !== "" ||
          this.paramOtherRequest !== "")
      );
    },
  },
  methods: {
    filterInput() {
      this.requestName = this.requestName.replace(/[^a-z-0-9]/g, "");
    },
    /**
     * Checks if the test response data is expandable by comparing its height with 100px.
     * The check is performed after the next DOM update cycle, since the height of the element
     * is not immediately available when the method is called.
     */
    checkTestResponseExpandable() {
      this.$nextTick(() => {
        let scrollHeight = 0;
        if (this.$refs.testResponseDataRaw)
          scrollHeight =
            scrollHeight + this.$refs.testResponseDataRaw.scrollHeight;
        if (this.$refs.testJSErrors)
          scrollHeight = scrollHeight + this.$refs.testJSErrors.scrollHeight;
        this.testResponseIsExpandable = scrollHeight > 100;
      });
    },
    /**
     * Edit an action for sending a message.
     * @param {number} index - The index of the action in the actions array.
     * @param {object} item - The action data.
     */
    editAction(index, item, group) {
      this.currentActionEditIndex = index;
      this.currentActionData = item;
      this.showCreateOrEditAction = group;
    },
    /**
     * Moves an item up or down in a given action group.
     * @param {string} group - The action group name.
     * @param {number} index - The index of the item to move.
     * @param {number} direction - The move direction (-1 for up, 1 for down).
     */
    moveActionItem(group, index, direction) {
      const newIndex = index + direction;
      if (newIndex < 0 || newIndex >= this.actions[group].length) return;

      const items = [...this.actions[group]];
      [items[index], items[newIndex]] = [items[newIndex], items[index]];
      this.$set(this.actions, group, items);
    },
    newAction(type) {
      this.showCreateOrEditAction = type;
    },
    saveAction(type, data = null, index = null) {
      if (!data) data = this.currentActionData;
      if (this.currentActionEditIndex > -1 && !index)
        index = this.currentActionEditIndex;
      if (
        this.currentActionEditIndex > -1 &&
        this.currentActionData.conditions
      ) {
        data.conditions = this.currentActionData.conditions;
      }
      switch (type) {
        case "setEntity":
        case "sendMessage":
        case "forceQuestion":
        case "setOperator":
          if (typeof index !== undefined && this.actions[type][index]) {
            this.actions[type][index] = data;
          } else {
            this.actions[type].push(data);
          }
          break;
      }
      this.showCreateOrEditAction = false;
      this.currentActionData = {};
    },
    handleSaveVariablesConditions(conditions) {
      this.currentActionData.conditions = conditions;
    },
    handleSaveMappedVariables(mappedVariables) {
      this.mappedVariables = { ...this.mappedVariables, ...mappedVariables };
      this.showVariableMapping = false;
    },
    handleUpdateMappedVariable(updatedVariable) {
      const { alias, path, transformations } = updatedVariable;

      if (!this.mappedVariables.mappedVariablesTransformation) {
        this.$set(this.mappedVariables, "mappedVariablesTransformation", {});
      }

      if (this.mappedVariables[alias]) {
        this.$set(this.mappedVariables, alias, path);
      } else {
        const oldAlias = Object.keys(this.mappedVariables).find(
          (key) =>
            this.mappedVariables[key] ===
            (this.editVariable ? this.editVariable.path : null)
        );
        if (oldAlias) {
          this.$delete(this.mappedVariables, oldAlias);
        }
        this.$set(this.mappedVariables, alias, path);
      }

      if (transformations !== undefined) {
        this.$set(
          this.mappedVariables.mappedVariablesTransformation,
          alias,
          transformations
        );
      }

      this.showVariableMapping = false;
    },
    handleCancelMappedVariable() {
      this.showVariableMapping = false;
    },
    variableEdit(alias) {
      this.showVariableMapping = true;
      if (this.mappedVariables[alias]) {
        this.editVariable = {
          alias,
          path: this.mappedVariables[alias],
          transformations: this.mappedVariables.mappedVariablesTransformation
            ? this.mappedVariables.mappedVariablesTransformation[alias]
            : { js: "", regex: [] },
        };
      }
    },
    variableDeleteConfirm(alias) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirmation"),
        text: this.$t("ConfirmDeleteOfX", [`"${alias}"`]),
        accept: () => this.variableDelete(alias),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    variableDelete(alias) {
      this.$delete(this.mappedVariables, alias);
    },
    extractEntities(stageEntities = null) {
      const entityKeys = ["params", "data", "auth", "headers"];
      let entities = stageEntities || [];
      entityKeys.forEach((key) => {
        if (this.request[key]) {
          entities = entities.concat(
            Object.values(this.request[key]).filter(
              (value) => value && value.startsWith("@")
            )
          );
        }
      });
      this.entities = [...new Set([...entities])];
    },

    /**
     * Opens the confirmation dialog before deleting an item.
     * @param {string} group - The action group name.
     * @param {number} index - The index of the item to delete.
     */
    confirmActionDelete(group, index) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirmation"),
        text: this.$t("ConfirmDeleteOfX", [this.$t("Action")]),
        accept: () => this.deleteActionItem(group, index),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    /**
     * Deletes an item from the actions object.
     */
    deleteActionItem(group, index) {
      this.actions[group].splice(index, 1);
      this.$set(this.actions, group, [...this.actions[group]]);

      this.$vs.notify({
        color: "success",
        title: this.$t("Deleted"),
        text: "",
        position: "top-right",
        time: 4000,
      });
    },
    createNew() {
      this.reset();
      this.showRequestForm = true;
      this.requestId = "";
    },
    searchRequests(value) {
      this.isSearching = true;
      this.searchQuery = value;
      clearTimeout(this.searchTimeout);

      if (!this.searchQuery) {
        this.isSearching = false;
        this.searchResults = [{}];
        return;
      }

      this.searchTimeout = setTimeout(() => {
        this.fetchRequests();
      }, 3000);
    },

    addExistingRequest(item) {
      // Check if _id already exists in requestList
      const exists = this.requestList.some(
        (request) => request._id === item._id
      );

      if (!exists) {
        this.requestList.push(item);

        this.$emit(
          "save",
          this.requestList.map((el) => el._id)
        );

        this.$vs.notify({
          color: "success",
          title: this.$t("Success"),
          text: "Request added successfully",
          position: "top-right",
          time: 4000,
        });
      } else {
        this.$vs.notify({
          color: "warning",
          title: this.$t("Warning"),
          text: "Request already exists in this question",
          position: "top-right",
          time: 4000,
        });
      }

      this.selectedRequest = null;
    },

    reset() {
      this.isSearching = false;
      this.searchTimeout = null;
      this.actions = {
        setEntity: [],
        sendMessage: [],
        setOperator: [],
        forceQuestion: [],
      };
      this.mappedVariables = {};
      this.editVariable = null;
      this.showVariableMapping = false;
      this.timeout = 20000;
      this.entities = [];
      this.showRequestForm = false;
      this.selectedRequest = null;
      this.searchQuery = "";
      this.searchResults = [];
      this.componentKey = 0;
      this.tabIndex = 0;
      this.form = null;
      this.responseTest = "";
      this.entitiesTest = {};

      this.testResponseStatus = null;
      this.testResponseStatusExpanded = false;
      this.testResponseIsExpandable = false;
      this.testResponseParsedResponse = {};
      this.testResponseData = "";
      this.testResponseDataRaw = "";
      this.testJSErrors = "";

      this.destination = null;
      this.paramType = null;
      this.paramKey = "";
      this.paramEntity = "";
      this.paramCustom = "";
      this.paramOtherRequest = "";

      this.requestId = "";
      this.requestName = "";
      this.requestDescription = "";
      this.requestStatus = 1;
      this.requestBodyDataType = "json";
      this.url = "";
      this.request = {
        url: "",
        method: "GET",
        headers: {},
        params: {},
        data: {},
        auth: {
          username: null,
          password: null,
        },
        responseType: "json",
      };
      this.showCreateOrEditAction = false;
      this.currentActionData = {};
      this.currentActionEditIndex = -1;
      this.actionsToExecute = {
        setEntity: [],
        sendMessage: [],
        setOperator: [],
        forceQuestion: [],
      };

      this.saveTimeout = null;
    },
    forceQuestionIDNode(id) {
      return this.formFields2.find((el) => el.id === id);
    },
    getForceQuestionLabel(id) {
      const element = this.formFields2.find((el) => el.id === id);
      if (element && element.label) return element.label;
      else if (element) return `Mensagem sem título. Id: ${id}`;
      else "Message not found";
    },
    async editRequest(_id) {
      this.reset();
      this.requestId = _id;
      this.showRequestForm = true;
      await this.fetchRequests();
    },
    async fetchRequests() {
      if (
        !this.isSearching &&
        !this.requestId &&
        !this.searchQuery &&
        !this.webhookWebhooksIds
      )
        return;
      if (!this.isSearching) this.$vs.loading();
      try {
        const response = await this.$http.get("/p/chat/flow-api-call", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            requestId: this.requestId,
            search: this.searchQuery,
            _ids: this.webhookWebhooksIds
              ? this.webhookWebhooksIds.join(",")
              : "",
          },
        });

        this.$vs.loading.close();
        const data = response.data.data;

        if (!data) {
          this.$vs.notify({
            title: "Error",
            text: "No data received",
            color: "danger",
          });
          return;
        }
        if (this.isSearching) {
          this.searchResults = data;
        } else if (Array.isArray(data) && data.length > 0) {
          this.requestList = data;
        } else {
          this.requestName = data.name !== undefined ? data.name : "";
          this.requestDescription = data.description ? data.description : "";
          this.request = data.request !== undefined ? data.request : {};
          this.url = data.request && data.request.url ? data.request.url : "";
          this.requestBodyDataType = data.bodyDataType ? data.bodyDataType : "";
          this.requestStatus = data.status ? data.status : "";
          this.mappedVariables = data.mappedVariables
            ? data.mappedVariables
            : {};
          this.actions = data.actions ? data.actions : [];
          this.timeout = data.timeout ? data.timeout : 20000;
          this.extractEntities();
        }
        this.isSearching = false;
      } catch (error) {
        this.isSearching = false;
        this.$vs.notify({
          title: "Request Failed",
          text: "Unable to fetch data",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async saveRequest() {
      try {
        this.$vs.loading();
        const response = await this.$http.post(
          `/p/chat/flow-api-call?acc=${this.$store.state.acc.current_acc.id}`,
          {
            save: true,
            _id: this.requestId,
            chat: this.$store.state.chatbotManagement.current_chatbot.id,
            acc: this.$store.state.acc.current_acc.id,
            name: this.requestName,
            timeout: this.timeout,
            requestDescription: this.requestDescription,
            request: this.request,
            bodyDataType: this.requestBodyDataType,
            status: this.requestStatus,
            mappedVariables: this.mappedVariables,
            actions: this.actions,
            description: this.requestDescription,
          }
        );

        this.$vs.loading.close();

        const responseData = response.data.data;

        this.reset();
        if (response.data.data.isNew) {
          this.requestList.push(response.data.data);
          this.selectedRequest = null;
          this.$emit(
            "save",
            this.requestList.map((el) => el._id)
          );
        } else {
          const index = this.requestList.findIndex(
            (item) => item._id === responseData._id
          );
          if (index !== -1) {
            this.$set(this.requestList, index, responseData);
          }
        }

        this.$vs.notify({
          color: "success",
          title: this.$t("Confirm"),
          text: "Request saved successfully",
          position: "top-right",
          time: 4000,
        });
      } catch (e) {
        this.$vs.loading.close();

        let errorMessage = this.$t("UnexpectedError");

        if (e.response && e.response.data && e.response.data.message) {
          errorMessage = e.response.data.message;
        }

        this.$vs.notify({
          color: "danger",
          title: this.$t("Error"),
          text: errorMessage,
          position: "top-right",
          time: 4000,
        });
      }
    },
    deleteRequestConfirm(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirmation"),
        text: this.$t("ConfirmDeleteOfX", [this.$t("request")]),
        accept: () => this.deleteRequest(id),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    deleteRequest(id) {
      this.$vs.loading.close();
      this.requestList = this.requestList.filter(
        (request) => request._id !== id
      );
      this.selectedRequest = null;

      this.$emit(
        "save",
        this.requestList.map((el) => el._id)
      );
    },
    moveItem(index, direction) {
      if (direction === "up" && index > 0) {
        [this.requestList[index], this.requestList[index - 1]] = [
          this.requestList[index - 1],
          this.requestList[index],
        ];
      } else if (direction === "down" && index < this.requestList.length - 1) {
        [this.requestList[index], this.requestList[index + 1]] = [
          this.requestList[index + 1],
          this.requestList[index],
        ];
      }
      this.requestList = [...this.requestList];
      this.scheduleSave();
    },
    scheduleSave() {
      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }

      this.saveTimeout = setTimeout(() => {
        this.saveOrder();
      }, 1000);
    },
    saveOrder() {
      this.$emit(
        "save",
        this.requestList.map((el) => el._id)
      );
    },

    testRequest() {
      this.actionsToExecute = {
        setEntity: [],
        sendMessage: [],
        setOperator: [],
        forceQuestion: [],
      };

      this.$vs.loading();
      this.$http
        .post(
          `/p/chat/flow-api-call-test?acc=${this.$store.state.acc.current_acc.id}`,
          {
            chat: this.$store.state.chatbotManagement.current_chatbot.id,
            acc: this.$store.state.acc.current_acc.id,
            _id: this.requestId,
            name: this.requestName,
            request: this.request,
            bodyDataType: this.requestBodyDataType,
            status: this.requestStatus,
            mappedVariables: this.mappedVariables,
            entities: this.entitiesTest,
            actions: this.actions,
            timeout: this.timeout,
          },
          { timeout: this.timeout + 500 }
        )
        .then(async (response) => {
          this.testJSErrors = "";
          if (!response.data.data.success) {
            this.testResponseDataRaw = JSON.stringify(
              response.data.data,
              null,
              "\t"
            );
            this.testResponseStatus = response.data.data.status || "Undefined";
          } else {
            this.testJSErrors = response.data.data.jsErrors
              ? "\n\nJS ERRORS: " + response.data.data.jsErrors
              : "";
            if (this.testJSErrors) {
              this.$vs.notify({
                color: "danger",
                title: this.$t("Error"),
                text: this.testJSErrors,
                position: "top-right",
                time: 4000,
              });
            }
            this.testResponseDataRaw = JSON.stringify(
              response.data.data.data,
              null,
              "\t"
            );
            this.testResponseStatus = response.data.data.status;
            this.actionsToExecute = response.data.data.actionsToExecute;
          }
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: this.$t("Success"),
            text: "Test response received",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.testResponseData = "";
          this.actionsToExecute = "";
          this.testResponseTimeout = 20000;
          this.testResponseDataRaw = JSON.stringify(
            error.response.data,
            null,
            "\t"
          );
          this.testResponseStatus = error.response.status;
          this.$vs.notify({
            color: "danger",
            title: this.$t("Error"),
            text: "Request error",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getTabTitle(tab) {
      if (tab === 0) return "Headers";
      if (tab === 1) return "Query Params";
      if (tab === 2) return "Body";
    },
    hideParams() {
      if (this.$refs.dataKeyValueTemplate)
        this.$refs.dataKeyValueTemplate.classList.replace("block", "hidden");
    },
    setParam() {
      if (!this.request[this.destination]) this.request[this.destination] = {};
      if (this.paramType === "entity")
        this.request[this.destination][this.paramKey] = `@${this.paramEntity}`;
      if (this.paramType === "custom") {
        this.request[this.destination][this.paramKey] = this.paramCustom;
        if (this.paramCustom.startsWith("@"))
          this.entities.push(this.paramCustom);
      }
      if (this.paramType === "otherRequest")
        this.request[this.destination][
          this.paramKey
        ] = `{${this.paramOtherRequest}}`;
      this.componentKey += 1;
      this.resetParams();
      this.hideParams();
    },
    unsetParam(destination, index) {
      delete this.request[destination][index];
      this.componentKey += 1;
    },
    resetParams() {
      this.testResponseStatus = null;
      this.testResponseData = null;
      this.testResponseDataRaw = null;
      this.testJSErrors = "";
      this.paramKey = "";
      this.paramEntity = "";
      this.paramCustom = "";
      this.paramOtherRequest = "";
      if (
        this.$refs.paramEntity &&
        !this.$refs.paramEntity.$el.classList.contains("hidden")
      )
        this.$refs.paramEntity.$el.classList =
          this.$refs.paramEntity.$el.classList + " hidden";
      if (
        this.$refs.paramCustom &&
        !this.$refs.paramCustom.$el.classList.contains("hidden")
      )
        this.$refs.paramCustom.$el.classList =
          this.$refs.paramCustom.$el.classList + " hidden";
      if (
        this.$refs.paramOtherRequest &&
        !this.$refs.paramOtherRequest.$el.classList.contains("hidden")
      )
        this.$refs.paramOtherRequest.$el.classList =
          this.$refs.paramOtherRequest.$el.classList + " hidden";
    },
    addParam(dest, type) {
      this.paramType = type;
      this.$refs.dataKeyValueTemplate.classList.replace("hidden", "block");
      this.resetParams();
      this.destination = dest;
      if (type === "custom")
        this.$refs.paramCustom.$el.classList.replace("hidden", "block");
      if (type === "entity")
        this.$refs.paramEntity.$el.classList.replace("hidden", "block");
      if (type === "otherRequest")
        this.$refs.paramOtherRequest.$el.classList.replace("hidden", "block");
    },
  },
};
</script>
<style scoped>
.vs-dropdown {
  z-index: 10000 !important;
}
.vs-popup--content {
  height: 100% !important;
}
.vs-list--avatar {
  width: 100% !important;
}
.action-groups {
  max-width: 600px;
  margin: auto;
}
.h-24 {
  height: 100px;
}
</style>
