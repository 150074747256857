<template slot="intentionsKeyEntity">
  <div>
    <div class="flex items-center justify-between">
      <div class="flex gap-x-2">
        <vs-button
          class="rounded-xxl"
          size="small"
          :type="type === 'all' ? 'filled' : 'border'"
          @click="type = 'all'"
          >{{ $t("All") }}</vs-button
        >
        <vs-button
          class="rounded-xxl"
          size="small"
          :type="type === 'intention' ? 'filled' : 'border'"
          @click="type = 'intention'"
          >{{ $t("Intention") }}</vs-button
        >
        <vs-button
          class="rounded-xxl"
          size="small"
          :type="type === 'entities' ? 'filled' : 'border'"
          @click="type = 'entities'"
          >{{ $t("Entity") }}</vs-button
        >
        <vs-button
          class="rounded-xxl"
          size="small"
          :type="type === 'keyEntities' ? 'filled' : 'border'"
          @click="type = 'keyEntities'"
          >{{ $t("KeyEntities") }}</vs-button
        >
      </div>
      <vs-button
        icon-pack="feather"
        icon="icon-plus"
        icon-after
        size="small"
        @click="popupIntentionEntitiesKey('', '', -1)"
      >
        {{ $t("Add") }}
      </vs-button>
    </div>
    <vs-table
      hoverFlat
      stripe
      :data="intentionKeyEntitiesTable"
      :noDataText="$t('EmptyTable')"
      class="mt-5 intentionTable"
      :notSpacer="true"
    >
      <template slot="thead">
        <vs-th>{{ $t("Content") }}</vs-th>
        <vs-th>{{ $t("Type") }}</vs-th>
        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">
            {{ tr.name }}
          </vs-td>
          <vs-td :data="tr.type">
            {{ nameType(tr.type) }}
          </vs-td>
          <vs-td :data="tr.actions">
            <div class="flex items-center gap-x-2">
              <vs-button
                type="flat"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                radius
                @click="popupIntentionEntitiesKey(tr.type, tr.name, indextr)"
              />
              <vs-button
                type="flat"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                radius
                @click="
                  popupDeleteIntentionEntitiesKey(tr.type, tr.name, indextr)
                "
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup
      :title="
        intentionKeyEntitiesToEdit.name === ''
          ? $t('AddIntentionAndEntities')
          : $t('Edit') +
            ' ' +
            nameType(intentionKeyEntitiesToEdit.type).toLowerCase()
      "
      :active.sync="newOrEditIntentionEntityKeyPopup"
      style="z-index: 10000000"
    >
      <div>
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="intention"
          >{{ $t("Intention") }}</vs-radio
        >
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="keyEntities"
          class="ml-6"
          >{{ $t("EntityKey") }}</vs-radio
        >
        <vs-radio
          v-model="intentionKeyEntitiesToEdit.type"
          vs-name="type"
          vs-value="entities"
          class="ml-6"
          >{{ $t("Entity") }}</vs-radio
        >
      </div>
      <div class="mt-3">
        <vs-input
          autofocus
          v-model="intentionKeyEntitiesToEdit.name"
          name="intention"
          type="text"
          label="Conteúdo"
          class="w-full"
        />
      </div>
      <vs-button
        :disabled="intentionKeyEntitiesToEdit.name.trim().length === 0"
        @click="addOrUpdateIntentionKeyEntity"
        icon-pack="feather"
        :icon="intentionKeyEntitiesIndex >= 0 ? 'icon-edit-2' : 'icon-plus'"
        class="float-right mt-6"
      >
        {{ $t(intentionKeyEntitiesIndex >= 0 ? "Edit" : "Add") }}
      </vs-button>
    </vs-popup>

    <vs-popup
      :title="
        $t('Delete') +
        ' ' +
        nameType(intentionKeyEntitiesToDelete.type).toLowerCase()
      "
      :active.sync="deleteIntentionEntitiesKey"
      style="z-index: 10000000"
    >
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            {{ $t("WantToDelete") }}
            {{ nameType(intentionKeyEntitiesToDelete.type).toLowerCase() }} -
            <b>{{ intentionKeyEntitiesToDelete.name }}</b>
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteIntentionKeyEntity"
            class="px-2"
          >
            {{ $t("Confirm") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import axios from "../../../../http/axios";

export default {
  name: "AgentIntentionsEntities",
  data() {
    return {
      mainIntention: "Atendimento IA",
      type: "all",
      popupDeleteIntention: false,
      intentionKeyEntitiesIndex: -1,
      iframeKey: 1,
      newOrEditIntentionEntityKeyPopup: false,
      promptText: "",
      popupUpload: false,
      typeUpload: "file",
      intentionsKeyEntities: [],
      intentionKeyEntitiesToEdit: {
        type: "intention",
        name: "",
      },
      intentionKeyEntitiesToDelete: {
        type: "intention",
        name: "",
      },
      deleteIntentionEntitiesKey: false,
    };
  },
  async mounted() {},
  props: {
    agent: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      required: true,
      type: Boolean,
      default: false,
    },
    intentions: {
      required: true,
      type: Array,
      default: () => [],
    },
    entities: {
      required: true,
      type: Array,
      default: () => [],
    },
    keyEntities: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    intentionKeyEntitiesTable() {
      if (this.type === "all") return this.intentionsKeyEntities;
      else
        return this.intentionsKeyEntities.filter(
          (item) => item.type === this.type
        );
    },
  },
  methods: {
    populateIntentionsEntities(data) {
      this.intentionsKeyEntities = [];
      if (Object.entries(data.intentions).length > 0)
        data.intentions.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "intention",
          });
        });
      if (Object.entries(data.keyEntities).length > 0)
        data.keyEntities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "keyEntities",
          });
        });
      if (Object.entries(data.entities).length > 0)
        data.entities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "entities",
          });
        });
    },
    addOrUpdateIntentionKeyEntity() {
      if (!this.intentionKeyEntitiesToEdit.name) return;

      if (
        this.intentionsKeyEntities.find(
          (i) =>
            i.name.toLowerCase() ===
            this.intentionKeyEntitiesToEdit.name.toLowerCase()
        )
      ) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text:
            (this.intentionKeyEntitiesToEdit.type === "intention"
              ? this.$t("Intention")
              : this.$t("EntityKey")) + ' ' + this.$t("AlreadyExists"),
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      if (this.intentionKeyEntitiesIndex === -1) {
        if (this.intentionKeyEntitiesToEdit.type === "entities")
          this.intentionKeyEntitiesToEdit.name =
            "@" + this.intentionKeyEntitiesToEdit.name;
        this.intentionsKeyEntities.push(this.intentionKeyEntitiesToEdit);
      } else {
        this.intentionsKeyEntities[this.intentionKeyEntitiesIndex].name =
          this.intentionKeyEntitiesToEdit.name;
        this.intentionsKeyEntities[this.intentionKeyEntitiesIndex].type =
          this.intentionKeyEntitiesToEdit.type;
      }

      this.newOrEditIntentionEntityKeyPopup = false;
    },
    deleteIntentionKeyEntity() {
      this.intentionsKeyEntities.splice(this.intentionKeyEntitiesIndex, 1);
      this.deleteIntentionEntitiesKey = false;
    },
    popupIntentionEntitiesKey(type, name, index) {
      this.newOrEditIntentionEntityKeyPopup = true;
      if (type === "" && name === "") {
        this.intentionKeyEntitiesIndex = -1;
        this.intentionKeyEntitiesToEdit = {
          type: "intention",
          name: "",
        };
      } else {
        this.intentionKeyEntitiesIndex = index;
        this.intentionKeyEntitiesToEdit = { type, name };
      }
    },
    popupDeleteIntentionEntitiesKey(type, name, index) {
      this.intentionKeyEntitiesToDelete.name = name;
      this.intentionKeyEntitiesToDelete.type = type;
      this.intentionKeyEntitiesIndex = index;
      this.deleteIntentionEntitiesKey = true;
    },
    nameType(type) {
      let name = "";

      switch (type) {
        case "intention":
          name = this.$t("Intention");
          break;
        case "entities":
          name = this.$t("Entity");
          break;
        case "keyEntities":
          name = this.$t("KeyEntities");
          break;
      }

      return name;
    },
    async fetchChatbot() {
      const res = await axios.get(`/p/chat/${this.$route.params.chatbot_id}`);

      this.iframeKey++;
      this.currentChatbot = res.data.data;

      this.mainIntention = this.currentChatbot.aiMainIntention;

      if (this.currentChatbot.intentions.length > 0)
        this.currentChatbot.intentions.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "intention",
          });
        });
      if (this.currentChatbot.keyEntities.length > 0)
        this.currentChatbot.keyEntities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "keyEntities",
          });
        });
      if (this.currentChatbot.entities.length > 0)
        this.currentChatbot.entities.map((item) => {
          this.intentionsKeyEntities.push({
            name: item,
            type: "entities",
          });
        });
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.vs-collapse {
  padding: 0 !important;
}
.con-content--item,
.vs-collapse-item--header {
  background: #fff !important;
}
.intentionTable table tr th:last-child {
  width: 100px;

  .vs-table-text {
    text-align: center;
    display: block;
  }
}
</style>
