<template v-slot: createAgent>
  <div>
    <form-wizard
      ref="wizard"
      :class="{ invalid: !isValid }"
      color="rgba(var(--vs-primary), 1)"
      errorColor="rgba(var(--vs-danger), 1)"
      :title="null"
      :subtitle="null"
      stepSize="xs"
      nextButtonText="Avançar"
      backButtonText="Voltar"
      finishButtonText="Salvar"
      transition="ease"
      @on-validate="handleValidation"
      @on-change="handleTabChange"
      @on-complete="createAgent"
    >
      <tab-content
        :title="$t('GeneralSettings')"
        class="mb-5"
        icon="feather icon-edit"
        :beforeChange="validateStepOne"
      >
        <agent-form
          ref="agentForm"
          :agent="currentAgent"
          :isEditing="isEditing"
        />
      </tab-content>
      <tab-content
        :title="$t('KnowledgeBase')"
        class="mb-5"
        icon="feather icon-file"
      >
        <knowledge-base
          ref="knowledgeBase"
          :agent="currentAgent"
          :isEditing="isEditing"
        />
      </tab-content>
      <tab-content
        :title="$t('IntentionsAndKeyEntities')"
        class="mb-5"
        icon="feather icon-flag"
      >
        <agent-intentions-entities
          ref="intentionsKeyEntitiesComponent"
          :agent="currentAgent"
          :isEditing="isEditing"
          :intentions="intentions"
          :entities="entities"
          :keyEntities="keyEntities"
        />
      </tab-content>
      <tab-content
        :title="$t('AgentFunctions')"
        class="mb-5"
        icon="feather icon-settings"
      >
        <agent-functions
          ref="agentFunctions"
          :agent="currentAgent"
          :isEditing="isEditing"
          :toIndex="toIndex"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import KnowledgeBase from "./KnowledgeBase.vue";
import AgentForm from "./AgentForm.vue";
import AgentIntentionsEntities from "./AgentIntentionsEntities.vue";
import AgentFunctions from "./AgentFunctions.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "createAgentPopup",
  components: {
    KnowledgeBase,
    AgentForm,
    AgentIntentionsEntities,
    AgentFunctions,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      createdAgent: {},
      intentions: [],
      entities: [],
      keyEntities: [],
      isValid: true,
    };
  },
  props: {
    currentAgent: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      required: true,
      type: Boolean,
      default: false,
    },
    toIndex: String,
  },
  watch: {
    currentAgent: {
      deep: true,
      handler(newVal) {
        this.currentAgent = newVal;
      },
    },
  },
  mounted() {
    if (this.isEditing) {
      this.$refs.wizard.activateAll();
    }
  },
  computed: {
    getIntentions() {
      if (
        !this.$refs.intentionsKeyEntitiesComponent ||
        !this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
      ) {
        return [];
      }
      return this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
        .filter((item) => item.type === "intention")
        .map((item) => item.name);
    },
    getEntities() {
      if (
        !this.$refs.intentionsKeyEntitiesComponent ||
        !this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
      ) {
        return [];
      }
      return this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
        .filter((item) => item.type === "entities")
        .map((item) => item.name);
    },
    getKeyEntities() {
      if (
        !this.$refs.intentionsKeyEntitiesComponent ||
        !this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
      ) {
        return [];
      }
      return this.$refs.intentionsKeyEntitiesComponent.intentionsKeyEntities
        .filter((item) => item.type === "keyEntities")
        .map((item) => item.name);
    },
  },
  methods: {
    validateStepOne() {
      return this.$refs.agentForm.validate();
    },
    handleValidation(validationResult) {
      this.isValid = validationResult;
      this.$refs.agentForm.handleValidation(validationResult);
    },
    handleTabChange(oldStep, newStep) {
      if (oldStep === 0 && newStep === 1) {
        this.getFiles();
      }
      if (oldStep === 1 && newStep === 2) {
        this.getIntentionsEntities();
      }
    },
    async getFiles() {
      if (this.isEditing) {
        this.$refs.knowledgeBase.getFiles(this.currentAgent.assistantId);
      }
      return;
    },
    async getIntentionsEntities() {
      try {
        if (this.isEditing) {
          const response = await this.$http.get("/p/chat/flows/list", {
            params: {
              acc: this.$store.state.acc.current_acc.id,
            },
          });
          const flow = response.data.data.find(
            (flow) => flow._id === this.currentAgent._id
          );
          const tableData = {
            intentions: [...flow.intentions],
            entities: [...flow.entities],
            keyEntities: [...flow.keyEntities],
          };
          this.$refs.intentionsKeyEntitiesComponent.populateIntentionsEntities(
            tableData
          );
        }

        return;
      } catch (error) {
        //console.error(error);
      }
    },
    async createAgent() {
      this.$vs.loading();

      try {
        if (!this.isEditing) {
          const res = await this.$http.post(
            "/p/chat/flows/create",
            {
              ...this.$refs.agentForm.getData(),
              type: "assistant",
              intentions: this.getIntentions,
              entities: this.getEntities,
              keyEntities: this.getKeyEntities,
              functions: this.$refs.agentFunctions.getFunctions(),
            },
            {
              params: {
                acc: this.$store.state.acc.current_acc.id,
              },
            }
          );
          this.createdAgent = res.data.data;
          await this.$refs.knowledgeBase.uploadFiles(res.data.data.assistantId);
        } else {
          const res = await this.$http.put(
            `/p/chat/flows/update/${this.currentAgent._id}`,
            {
              ...this.$refs.agentForm.getData(),
              type: "assistant",
              intentions: this.getIntentions,
              entities: this.getEntities,
              keyEntities: this.getKeyEntities,
              functions: this.$refs.agentFunctions.getFunctions(),
            },
            {
              params: {
                acc: this.$store.state.acc.current_acc.id,
              },
            }
          );
          this.createdAgent = res.data.data;
          await this.$refs.knowledgeBase.uploadFiles(res.data.data.assistantId);
        }
        this.$refs.knowledgeBase.savedFiles = [];
        this.$emit("closeAgentPopup", {
          agent: this.createdAgent,
          toIndex: this.$refs.agentFunctions.getToIndex(),
        });

        this.$vs.notify({
          title: "Sucesso",
          text: this.isEditing
            ? this.$t("AgentCreated")
            : this.$t("AgentEdited"),
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000,
        });
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: error.response.data.message,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
<style>
.checked .wizard-icon,
.checked + .stepTitle {
  color: #7367f0ff;
}

.vue-form-wizard.xs.invalid
  .wizard-navigation
  .wizard-progress-with-circle
  .wizard-progress-bar {
  background-color: rgba(var(--vs-danger), 1) !important;
}
</style>
