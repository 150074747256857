<template>
  <div>
    <div class="flex mb-4 items-center ml-1">
      <span class="text-md font-semibold mr-3">Conditions</span>
      <vs-button
        color="primary"
        size="small"
        @click="addCondition"
        type="border"
        icon="add"
      />
    </div>

    <vs-card v-if="editingCondition !== null" class="p-4 border mt-4">
      <h4 class="text-lg font-semibold mb-4">
        {{ editingIndex === null ? "Add Condition" : "Edit Condition" }}
      </h4>
      <div class="flex item-center space-x-2">
        <vs-select
          v-if="displayLogicalOperator"
          label="Logical Operator"
          v-model="editingCondition.logicalOperator"
          class="mb-2"
        >
          <vs-select-item value="and" text="AND" />
          <vs-select-item value="or" text="OR" />
        </vs-select>
        <vs-select
          autocomplete
          v-model="editingCondition.conditionVariable"
          label="Condition Variable"
          class="mb-2"
        >
          <vs-select-item
            v-for="(path, alias) in mappedVariables"
            :key="`option_select_${alias}`"
            :value="alias"
            :text="alias"
            :class="{ hidden: alias === 'mappedVariablesTransformation' }"
          />
        </vs-select>

        <vs-select
          label="Condition"
          autocomplete
          v-model="editingCondition.condition"
          class="mb-2"
          @change="handleConditionChange"
        >
          <vs-select-item value="equal" text="equal" />
          <vs-select-item value="not_equal" text="not equal" />
          <vs-select-item value="greater" text="greater" />
          <vs-select-item value="less" text="less" />
          <vs-select-item value="greater_equal" text="greater or equal" />
          <vs-select-item value="less_equal" text="less or equal" />
          <vs-select-item value="contains" text="contains" />
          <vs-select-item value="not_contains" text="does not contain" />
          <vs-select-item value="starts_with" text="starts with" />
          <vs-select-item value="ends_with" text="ends with" />
          <vs-select-item value="is_empty" text="is empty" />
          <vs-select-item value="regex" text="regex" />
        </vs-select>

        <vs-input
          label="Condition Value"
          type="text"
          class="mb-2"
          v-model="editingCondition.conditionValue"
          :disabled="editingCondition.condition === 'is_empty'"
        />
      </div>
      <div class="flex space-x-2 mt-4">
        <vs-button color="primary" size="small" @click="saveCondition"
          >Save</vs-button
        >
        <vs-button type="border" size="small" @click="cancelEdit"
          >Cancel</vs-button
        >
      </div>
    </vs-card>

    <vs-table stripe v-show="conditionsList.length > 0" :data="conditionsList">
      <template slot="thead">
        <vs-th class="w-10"> </vs-th>
        <vs-th>Variable</vs-th>
        <vs-th>Condition</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template>
        <vs-tr v-for="(condition, index) in conditionsList" :key="index">
          <vs-td>
            <span v-if="index > 0">{{
              condition.logicalOperator || "N/A"
            }}</span>
          </vs-td>
          <vs-td>{{ condition.conditionVariable || "N/A" }}</vs-td>
          <vs-td>{{ getConditionLabel(condition.condition) }}</vs-td>
          <vs-td>{{ condition.conditionValue || "N/A" }}</vs-td>
          <vs-td>
            <vs-button
              size="small"
              color="warning"
              @click="editCondition(index)"
              >Edit</vs-button
            >
            <vs-button
              size="small"
              color="danger"
              class="ml-2"
              @click="removeCondition(index)"
              >Remove</vs-button
            >
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  name: "ConditionsCard",

  props: {
    mappedVariables: {
      type: Object,
      required: true,
    },
    conditions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      conditionsList: [],
      editingIndex: null,
      editingCondition: null,
    };
  },

  computed: {
    displayLogicalOperator() {
      return (
        this.conditionsList.length > 1 ||
        (this.editingIndex === null && this.conditionsList.length > 0)
      );
    },
  },

  watch: {
    conditions: {
      handler(newConditions) {
        this.conditionsList = newConditions.length ? [...newConditions] : [];
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    addCondition() {
      this.editingIndex = null;
      this.editingCondition = {
        condition: "",
        conditionVariable: "",
        conditionValue: "",
        logicalOperator: "and",
      };
    },

    editCondition(index) {
      this.editingIndex = index;
      this.editingCondition = { ...this.conditionsList[index] };
    },

    handleConditionChange() {
      if (this.editingCondition.condition === "is_empty") {
        this.editingCondition.conditionValue = "";
      }
    },

    saveCondition() {
      if (this.editingIndex !== null) {
        this.$set(this.conditionsList, this.editingIndex, {
          ...this.editingCondition,
        });
      } else {
        this.conditionsList.push({ ...this.editingCondition });
      }
      this.saveConditions();
      this.cancelEdit();
    },

    removeCondition(index) {
      this.conditionsList.splice(index, 1);
      this.saveConditions();
    },

    cancelEdit() {
      this.editingIndex = null;
      this.editingCondition = null;
    },

    saveConditions() {
      this.$emit("save", this.conditionsList);
    },

    getConditionLabel(condition) {
      const labels = {
        equal: "is equal to",
        not_equal: "is not equal to",
        greater: "is greater than",
        less: "is less than",
        greater_equal: "is greater or equal to",
        less_equal: "is less or equal to",
        contains: "contains",
        not_contains: "does not contain",
        starts_with: "starts with",
        ends_with: "ends with",
        is_empty: "is empty",
        regex: "matches regex",
      };
      return labels[condition] || "Condition";
    },
  },
};
</script>
