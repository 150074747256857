<template v-slot:knowledgeBase>
  <div>
    <vs-table
      stripe
      :hoverFlat="true"
      :data="savedFiles"
      class="w-full"
      pagination
      max-items="10"
      :noDataText="$t('EmptyTable')"
    >
      <template v-slot:thead>
        <vs-th>{{ $t("Content") }}</vs-th>
        <vs-th>{{ $t("Type") }}</vs-th>
        <vs-th>Status</vs-th>
        <vs-th class="flex justify-center">{{ $t("Actions") }}</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">
            {{ tr.name }}
          </vs-td>
          <vs-td :data="tr.type">
            {{
              tr.type === "File" || tr.type === "text/plain"
                ? "Arquivo"
                : "Site"
            }}
          </vs-td>
          <vs-td :data="tr.status">
            <vs-chip
              transparent
              :color="
                tr.status === 'Error'
                  ? 'danger'
                  : tr.status === 'Treinando'
                  ? 'primary'
                  : tr.status === 'Treinado'
                  ? 'success'
                  : 'dark'
              "
            >
              {{
                tr.status === "Error"
                  ? $t("Error")
                  : tr.status === "Treinando"
                  ? $t("Training")
                  : tr.status === "Treinado"
                  ? $t("Trained")
                  : $t("NotTrained")
              }}
            </vs-chip>
          </vs-td>
          <vs-td>
            <div class="flex justify-center">
              <!-- <vs-tooltip
                v-if="tr.type === 'Url'"
                class="self-center mr-3 mt-2"
                :text="`Atualizado em ${formatDate(
                  tr.updatedAt
                )} (Atualização diária)`"
              >
                <vs-icon icon-pack="feather" icon="icon-info" class="text-grey">
                </vs-icon>
              </vs-tooltip> -->
              <vs-button
                v-if="tr.type !== 'Url'"
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-download"
                class="mt-1"
                target
                :href="tr.url"
              />

              <vs-button
                v-else
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-external-link"
                class="mt-1"
                :href="
                  tr.name.startsWith('http') ? tr.name : `http://${tr.name}`
                "
                target="_blank"
              />
              <vs-button
                type="flat"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                class="mt-1"
                @click="
                  tr.assistantId
                    ? openPopupDeleteFile(tr)
                    : removeSavedFiles(indextr)
                "
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-row>
      <vs-col>
        <vs-button
          icon-pack="feather"
          icon="icon-upload"
          icon-after
          class="w-full"
          @click="popupUpload = true"
        >
          {{ $t("AddContent") }}
        </vs-button>
      </vs-col>
    </vs-row>

    <vs-popup
      :title="$t('AddContent')"
      :active.sync="popupUpload"
      style="z-index: 10000000"
    >
      <vs-row vs-type="flex" vs-justify="space-around">
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'file'"
            :class="[typeUpload === 'file' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="file">{{
              $t("File")
            }}</vs-radio>
          </div>
        </vs-col>
        <vs-col vs-w="5">
          <div
            class="border-solid border rounded-md p-4 flex items-center h-16 cursor-pointer hover:border-primary"
            @click="typeUpload = 'site'"
            :class="[typeUpload === 'site' ? 'border-primary' : 'border-grey']"
          >
            <vs-radio v-model="typeUpload" vs-name="radios1" vs-value="site"
              >Site</vs-radio
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <p class="text-base text-center">
            {{ $t("SupportedFormats") }}<br />
            {{ $t("FileLimit") }}
          </p>
        </vs-col>
        <vs-col class="mt-5" v-if="typeUpload === 'file'">
          <custom-tag
            v-for="(item, index) in filesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeFile(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="FileTextIcon"
          />
        </vs-col>
        <vs-col class="mt-5" v-else>
          <custom-tag
            v-for="(item, index) in sitesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeSite(index)"
            :class="{ 'mt-3': index > 0 }"
            icon="LinkIcon"
          />
        </vs-col>
        <vs-col class="mt-3" vs-w="12" v-if="typeUpload === 'file'">
          <input
            type="file"
            class="hidden"
            ref="fileUpload"
            accept=".pdf, .txt, .doc, .docx"
            multiple
            v-on:input="handleFilesBeforeUpload"
          />
          <vs-button
            icon-pack="feather"
            icon="icon-upload"
            icon-after
            @click="$refs.fileUpload.click()"
            class="w-full"
          >
            {{ $t("AddFiles") }}
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="9" v-else>
          <vs-input
            v-model="site"
            :placeholder="$t('InsertURL')"
            type="url"
            icon-pack="feather"
            icon="icon-globe"
            class="w-12/12"
            :danger="!isUrlValid && site.length > 0"
            :danger-text="$t('InvalidURL')"
          />
        </vs-col>
        <vs-col class="mt-5" vs-w="3" v-if="typeUpload === 'site'">
          <vs-button
            icon-pack="feather"
            icon="icon-plus"
            icon-after
            @click="
              sitesToUpload.push(site);
              site = '';
            "
            :disabled="
              site.length === 0 || !isUrlValid || sitesToUpload.length >= 100
            "
          >
            {{ $t("Add") }}
          </vs-button>
        </vs-col>
        <vs-col class="mt-5" vs-w="12">
          <vs-button
            icon-pack="feather"
            icon="icon-save"
            icon-after
            class="w-full"
            color="success"
            @click="saveFilesLocally()"
            :disabled="typeUpload === 'site' && sitesToUpload.length === 0"
          >
            {{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
    <vs-popup
      title="Deletar conteúdo"
      :active.sync="popupDeleteFile"
      style="z-index: 10000000"
    >
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover o conteúdo - {{ fileToDelete.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteFile(fileToDelete._id, fileToDelete.assistantId)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import CustomTag from "@/components/CustomTag.vue";
export default {
  name: "KnowledgeBase",
  components: {
    CustomTag,
  },
  data() {
    return {
      mainIntention: "Atendimento IA",
      type: "all",
      formData: undefined,
      fileToDelete: {},
      popupDeleteIntention: false,
      intentionKeyEntitiesIndex: -1,
      iframeKey: 1,
      newOrEditIntentionEntityKeyPopup: false,
      popupEditFunction: false,
      popupEditFunctionType: null,
      popupEditFunctionTitle: "",
      popupEditFunctionValue: "",
      aiAddTagDefault: `{
  "description": "Determine alguma tag para o usuário quando pegar alguma informação importante como empresa, cargo, email, telefone",
  "parameters": {
    "type": "object",
    "properties": {
      "tag": {
        "type": "string",
        "description": "exemplo de tag 'empresa: valor', 'email: valor' sempre com chave: valor"
      }
    },
    "required": [
      "tag"
    ]
  }
}`,
      aiFunctionCloseConversationDefault: `{
  "description": "Execute essa função quando o usuário nao quiser mais conversar",
  "parameters": {
    "type": "object",
    "properties": {},
    "required": []
  }
}`,
      aiFunctionForwardHumanDefault: `{
  "description": "Se a pessoa quiser mais informações ou informações que voce nao possua determine o setor para a pessoa: -vendas -suporte",
  "parameters": {
      "type": "object",
      "properties": {
          "setor": {
              "type": "string",
              "description": "vendas ou suporte"
          }
      },
      "required": ["setor"]
  }
}`,
      currentChatbot: null,
      chatbotGoal: "",
      chatbotGoalOptions: [],
      goal: null,
      promptText: "",
      popupUpload: false,
      typeUpload: "file",
      filesToUpload: [],
      savedFiles: [],
      popupDeleteFile: false,
      site: "",
      sitesToUpload: [],
      addTag: false,
      humanTransfer: false,
      closeConversation: false,
      gptChatModel: "gpt-4o-mini",
      gptChatModelOptions: [
        { value: "gpt-3.5-turbo", text: "GPT-3.5 Turbo" },
        { value: "gpt-4o", text: "GPT-4o" },
        { value: "gpt-4o-mini", text: "GPT-4o Mini" },
        { value: "o3-mini-2025-01-31", text: "o3-mini" },
      ],
      intentionsKeyEntities: [],
      intentionKeyEntitiesToEdit: {
        type: "intention",
        name: "",
      },
      intentionKeyEntitiesToDelete: {
        type: "intention",
        name: "",
      },
      deleteIntentionEntitiesKey: false,
    };
  },
  props: {
    agent: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  watch: {
    agent: {
      deep: true,
      handler(newVal) {
        this.agent = newVal;
      },
    },
  },
  computed: {
    // disableButtons() {
    //   return this.totalFiles >= this.filesLimit;
    // },
    // totalFiles() {
    //   return this.rowData.length + this.filesToUpload.length;
    // },
    isUrlValid() {
      if (!this.site) return false;

      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator

      return urlPattern.test(this.site);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleFilesBeforeUpload(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      files.forEach((file) => this.filesToUpload.push(file));
    },

    async saveFilesLocally() {
      this.popupUpload = false;
      this.formData = new FormData();

      if (this.typeUpload === "file") {
        this.filesToUpload.forEach((item, i) => {
          this.formData.append("files" + i, item);
          this.savedFiles.push({
            name: item.name,
            type: "File",
            fileContent: item,
          });
          this.filesToUpload = [];
        });
      } else {
        this.sitesToUpload.forEach((item, i) => {
          this.formData.append("url" + i, item);
          this.savedFiles.push({
            name: item,
            type: "Url",
            fileContent: null,
          });
          this.sitesToUpload = [];
        });
      }

      this.$vs.notify({
        time: 2500,
        title: this.$t("Success"),
        text: this.$t("ContentAdded"),
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
        position: "top-right",
      });
    },
    async uploadFiles(assistantId) {
      if (!this.formData) return;

      await this.$http.post("/p/chat/ai/assistant/files/save?", this.formData, {
        headers: {
          Authorization: "Bearer " + this.$store.state.auth.accessToken,
          "Content-Type": "multipart/form-data",
        },
        params: {
          acc: this.$store.state.acc.current_acc.id,
          assistantId,
        },
      });
    },
    async getFiles(assistantId) {
      const response = await this.$http.get("/p/chat/ai/assistant/files", {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          assistantId,
        },
      });
      this.savedFiles = response.data.data;
    },
    removeFile(index) {
      this.filesToUpload.splice(index, 1);
    },
    removeSite(index) {
      this.sitesToUpload.splice(index, 1);
    },
    removeSavedFiles(index) {
      this.savedFiles.splice(index, 1);
    },
    openPopupDeleteFile(item) {
      this.fileToDelete = item;
      this.popupDeleteFile = true;
    },
    async deleteFile(id, assistantId) {
      try {
        this.popupDeleteFile = false;

        this.savedFiles = this.savedFiles.filter((item) => item._id !== id);

        const response = await this.$http.delete(
          "/p/chat/ai/assistant/files/delete/" +
            id +
            "?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.$route.params.chatbot_id +
            "&assistantId=" +
            assistantId
        );

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: response.data.message,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
  },
};
</script>
