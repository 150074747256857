<template v-slot: agentForm>
  <div class="mx-12">
    <vs-row>
      <vs-col vs-w="12">
        <vs-input
          name="agentName"
          v-model="agentName"
          type="text"
          class="w-full"
          :label="$t('AgentName')"
        />
      </vs-col>
      <span
        v-if="!valid"
        class="mt-1"
        :style="{ color: 'rgba(var(--vs-danger), 1)' }"
        >{{ $t("Required") }}</span
      >
    </vs-row>
    <!-- <vs-row>
      <vs-col vs-w="12" class="mt-4">
        <vs-input
          name="intention"
          v-model="mainIntention"
          type="text"
          class="w-full"
          label="Intenção Padrão"
        />
      </vs-col>
    </vs-row> -->
    <vs-row>
      <vs-col
        :vs-w="isMaster ? '9' : '12'"
        :class="isMaster ? 'mt-4 pr-2' : 'mt-4'"
      >
        <vs-select
          v-model="chatbotGoal"
          :label="$t('SelectGoal')"
          class="w-full"
        >
          <vs-select-item
            v-for="(item, index) in chatbotGoalOptions"
            :key="index"
            :value="item._id"
            :text="item.name"
          />
        </vs-select>
      </vs-col>
      <vs-col vs-w="3" class="mt-4" v-if="isMaster">
        <vs-select
          v-model="gptChatModel"
          :label="$t('GptModel')"
          class="w-full"
        >
          <vs-select-item
            v-for="(item, index) in gptChatModelOptions"
            :key="index"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="12" class="mt-4">
        <label class="vs-input--label mb-2 mr-1">{{
          $t("AdvancedBehaviorSettings")
        }}</label>
        <vs-textarea rows="15" v-model="instructions" />
      </vs-col>
    </vs-row>
    <span
      v-if="!valid"
      class="mt-1"
      :style="{ color: 'rgba(var(--vs-danger), 1)' }"
      >{{ $t("Required") }}</span
    >
  </div>
</template>
<script>
export default {
  name: "createAgentPopup",
  data() {
    return {
      agents: [],
      agentName: "",
      mainIntention: "",
      chatbotGoal: "",
      valid: true,
      chatbotGoalOptions: [],
      gptChatModel: "gpt-4o-mini",
      gptChatModelOptions: [
        { value: "gpt-4o", text: "GPT-4o" },
        { value: "gpt-4o-mini", text: "GPT-4o Mini" },
        { value: "o3-mini-2025-01-31", text: "o3-mini" },
      ],
      assistantModel: "",
      instructionsDefault:
        "Você é uma assistente inteligente para auxiliar clientes e leads com dúvidas e sugestões. Duotalk é uma plataforma omnichannel que facilita o marketing, vendas e atendimento das empresas nos canais whatsapp, instagram, messenger, site entre outros.",
      instructions: "",
    };
  },
  props: {
    agent: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    isEditing: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  watch: {
    agent: {
      deep: true,
      handler(newVal) {
        // Removed: this.agent = newVal;
        this.agentName = newVal.name;
        this.gptChatModel = newVal.model || "";
        this.instructions = newVal.instructions || this.instructionsDefault;
      },
    },
    isEditing: {
      deep: true,
      handler(newVal) {
        if (newVal === true) {
          this.agentName = this.agent.name;
          this.gptChatModel = this.agent.model || "";
          this.instructions =
            this.agent.instructions || this.instructionsDefault;
        } else {
          this.agentName = "";
          this.gptChatModel = "";
          this.instructions = "";
        }
      },
    },
  },
  computed: {
    isMaster() {
      return this.$store.state.user.master;
    },
  },
  methods: {
    validate() {
      return (
        this.agentName &&
        this.agentName.trim() !== "" &&
        this.instructions &&
        this.instructions.trim() !== ""
      );
    },
    handleValidation(validationResult) {
      this.valid = validationResult;
    },
    getData() {
      return {
        name: this.agentName,
        model: this.gptChatModel,
        instructions: this.instructions,
      };
    },
    async getGoals() {
      try {
        const response = await this.$http.get(
          "/p/chat/assistant/objective/list"
        );
        this.chatbotGoalOptions = response.data.data;
        this.chatbotGoal = response.data.data[0]._id;
        this.instructions = response.data.data[0].instruction;
      } catch (error) {
        //console.log(error);
      }
    },
  },
  async mounted() {
    await this.getGoals();
    if (Object.keys(this.agent).length !== 0 && this.isEditing) {
      this.agentName = this.agent.name;
      this.gptChatModel = this.agent.model || "";
      this.instructions = this.agent.instructions || this.instructionsDefault;
    }
  },
};
</script>
